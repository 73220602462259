import styles from './index.module.less';

interface HighLightPropBaic {
  content: string;
}

interface HighLightPropBaicUsingKeyWord extends HighLightPropBaic {
  /*
   * keyword used for searching text
   */
  keyword: string;
  ignoreCase?: boolean;
  tag?: never;
}

const SupportedHighLightTags = ['em'] as const;

interface HighLightPropBaicUsingTag extends HighLightPropBaic {
  keyword?: never;
  ignoreCase?: never;
  /**
   * highight by html tag.
   * Tag will be replaced to <em> for highlight,
   * other tags will be displayed as text.
   */
  tag: (typeof SupportedHighLightTags)[number];
}

type HighLightProp = HighLightPropBaicUsingKeyWord | HighLightPropBaicUsingTag;

/**
 * HighLight content either by html tag or keyword
 */
const HighLight = ({ content, keyword = '', ignoreCase = true, tag }: HighLightProp) => {
  if (tag && SupportedHighLightTags.includes(tag)) {
    const parts = content.split(new RegExp(`(<${tag}>)(.*?)(</${tag}>)`));
    if (parts.length === 1) return <span key={content}>{content}</span>;
    let flag = false;
    let inside: string | null = null;
    return (
      <span className={styles.highlight} key={content}>
        {parts.map((part, i) => {
          if (part === `<${tag}>`) {
            flag = true;
            return null;
          } else if (part === `</${tag}>`) {
            const el = <em key={i}>{inside}</em>;
            flag = false;
            inside = null;
            return el;
          } else if (flag) {
            inside = part;
            return null;
          } else {
            return part;
          }
        })}
      </span>
    );
  }

  const ikeyword = ignoreCase ? keyword.toLowerCase() : keyword;
  const icontent = ignoreCase ? content.toLowerCase() : content;

  if (ikeyword === '' || !icontent.includes(ikeyword)) {
    return (
      <span key={content} className={styles.highlight}>
        {content}
      </span>
    );
  }

  const parts = icontent.split(ikeyword);
  let index = 0;
  return (
    <span className={styles.highlight}>
      {parts.map((part, i) => {
        if (i === parts.length - 1) return <span key={i}>{content.substring(index)}</span>;
        const el = (
          <span key={i}>
            {content.substring(index, index + part.length)}
            <em className={styles.mark}>
              {content.substring(index + part.length, index + part.length + keyword.length)}
            </em>
          </span>
        );
        index += part.length + keyword.length;
        return el;
      })}
    </span>
  );
};
export default HighLight;
