import { create } from 'zustand';

interface AutoSendState {
  autoChatSend: boolean;
  autoPptSend: boolean;
  writingSend: boolean;
  autoChatOption: any;
  autoPptOption: any;
  autoWritingOption: any;
  setAutoChatSend: (payload: boolean) => void;
  setAutoPptSend: (payload: boolean) => void;
  setWritingSend: (payload: boolean) => void;

  setAutoChatOption: (payload: any) => void;
  setAutoPptOption: (payload: any) => void;
  setAutoWritingOption: (payload: any) => void;
  clearAutoSend: () => void;
}

/** 自动发送的一些参数设 */
const useAutoSendStore = create<
  AutoSendState
>(
  (set) => ({
    autoChatSend: false,
    setAutoChatSend: (payload: boolean) => set(() => ({ autoChatSend: payload })),
    autoChatOption: {},
    setAutoChatOption: (payload: any) => set(() => ({ autoChatOption: payload })),

    autoPptSend: false,
    setAutoPptSend: (payload: boolean) => set(() => ({ autoPptSend: payload })),
    autoPptOption: {},
    setAutoPptOption: (payload: any) => set(() => ({ autoPptOption: payload })),

    writingSend: false,
    setWritingSend: (payload: boolean) => set(() => ({ writingSend: payload })),
    autoWritingOption: {},
    setAutoWritingOption: (payload: any) => set(() => ({ autoWritingOption: payload })),
    clearAutoSend: () => set(() => ({
      autoChatSend: false,
      autoChatOption: {},
      autoPptSend: false,
      autoPptOption: {},
      writingSend: false,
      autoWritingOption: {},
    })),
  }),
);

export default useAutoSendStore;