/**
 * 维护 PPT 相关的外层业务逻辑
 */

import { operationType } from '@/components/PresentationView/config';
import { PresentationOptions } from '@/components/PresentationView/types';
import { editImg } from '@/services';
import { ImagePanelRef } from '@/components/PresentationView/components/ImagePanel';
import useGASendEvent from '@/hooks/useGASendEvent';
import { getImageSize, getImageDimensionsFromURL } from '@/components/PresentationView/tools';
import { getPPTThemeConfigByUuid } from '@/services/themes';
import { addStyleToBody, tokenToCss } from '@/common/pptStyle';
import { useState } from 'react';

interface EditorImgType {
  channelId: string;
  imageUrl: string;
  newImg: boolean;
  nodeId: string;
}

const REPORT_IMAGE_EVENT_MODE = ['gallery', 'upload'];

export const usePPTImage = () => {
  const addImgHistory = async ({ channelId, imageUrl, newImg, nodeId }: EditorImgType) => {
    try {
      await editImg({
        channelId: channelId,
        imageUrl: imageUrl,
        newImg: newImg,
        nodeId: nodeId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    addImgHistory,
  };
};
// 当用户操作PPT中图片相关的逻辑时，在这里进行统一处理
export const useImagePanelOperation = (
  channelId: string,
  imagePanelRef: React.RefObject<ImagePanelRef>,
  aiGenPanelRef: React.RefObject<ImagePanelRef>,
) => {
  const { addImgHistory } = usePPTImage();
  const { sendEvent } = useGASendEvent();
  const contentOperationHandler: PresentationOptions['onContentOperation'] = async (evt) => {
    // 打开图片存储桶面板
    if (evt.actionType === operationType.openGalleryPanel) {
      imagePanelRef.current?.open(evt.payload);
    }
    // 当图片上传完成时，记录图片上传历史到 history 桶中
    if (evt.actionType === operationType.imgUploadFinished && channelId) {
      addImgHistory({
        channelId: channelId,
        imageUrl: evt.payload.imageUrl,
        newImg: evt.payload.newImg,
        nodeId: evt.payload.node.attrs.id,
      });
    }
    // 切换图片之后的事件
    if (evt.actionType === operationType.imgChanged && channelId) {
      addImgHistory({
        channelId: channelId,
        imageUrl: evt.payload.imageUrl,
        newImg: evt.payload.newImg,
        nodeId: evt.payload.node.attrs.id,
      });
    }
    // 图片加载完成后，进行埋点
    if (evt.actionType === operationType.imgOnLoaded && channelId) {
      const url = new URL(evt.payload.imageUrl);
      const searchParams = new URLSearchParams(url.search);
      const mode = searchParams.get('mode') || '';
      const name = searchParams.get('name') || '';
      if (!REPORT_IMAGE_EVENT_MODE.includes(mode)) return;

      try {
        const imageDimensions = await getImageDimensionsFromURL(evt.payload.imageUrl);
        const imageSize = await getImageSize(evt.payload.imageUrl);
        sendEvent('CompletePPTImageLoad', {
          url: evt.payload.imageUrl,
          timestamp: Date.now(),
          width: imageDimensions.width,
          height: imageDimensions.height,
          size: imageSize,
          name,
        });
      } catch (error) {
        console.log(error);
      }
    }
    // 打开 AI 生图面板
    if (evt.actionType === operationType.openAiGenPanel) {
      aiGenPanelRef.current?.open(evt.payload);
    }
  };

  return {
    contentOperationHandler,
  };
};

export const usePPTTheme = () => {
  const [themeLoading, setThemeLoading] = useState(false);

  const genThemeCss = (themeId: string, themeStr: string) => {
    const cssStr = tokenToCss(JSON.parse(themeStr));
    addStyleToBody(themeId, cssStr);
  };

  const setThemeCssFromRemote = async (themeId: string) => {
    const existingStyleNode = document.getElementById(themeId);
    if (existingStyleNode) {
      return; // Skip loading if the theme CSS already exists
    }
    if (!themeId) return;
    setThemeLoading(true);
    try {
      const res = await getPPTThemeConfigByUuid({ uuid: themeId });
      if (res.data) {
        genThemeCss(themeId, res.data.content);
      }
      setThemeLoading(false);
    } catch (error) {
      console.error('loading theme error');
    } finally {
      setThemeLoading(false);
    }
  };

  return {
    themeLoading,
    setThemeCssFromRemote,
    genThemeCss,
  };
};
