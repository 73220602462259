/**
 * 输入框图片组件
 */
import { useState } from 'react';
import CustomIcon from '@/components/CustomIcon';
import { Progress, Image } from 'antd';

interface ImageListProps {
  imageUrls?: string[];
  isUploadImage?: boolean;
  imgProgress?: number;
  handleRemoveImages?: () => void;
}

interface InputImageProps {
  /**图片url */
  url?: string;
  /**上传进度 */
  progress?: number;
  onRemove?: () => void;
}
const InputImage: React.FC<InputImageProps> = (props) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className="input-img">
      <CustomIcon className="close-icon" type="close" onClick={props?.onRemove} />
      {props?.url && (
        <div className="file-img-contain" style={{ backgroundImage: `url(${props?.url})` }}>
          <CustomIcon type="popai-chakan" onClick={() => setVisible(true)} />
          <Image
            style={{ display: 'none' }}
            preview={{
              visible,
              src: props.url,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </div>
      )}
      {props?.progress && (
        <div className="progress">
          <Progress
            type="circle"
            size={24}
            strokeLinecap="butt"
            strokeColor="#3C38FF"
            percent={props?.progress}
            format={() => ''}
          />
          <span>{props?.progress}%</span>
        </div>
      )}
    </div>
  );
};

const ImageList: React.FC<ImageListProps> = (props) => {
  const { imageUrls = [], isUploadImage = false, imgProgress = 0, handleRemoveImages } = props;
  if (imageUrls.length > 0 || isUploadImage) {
    return (
      <div className="input-files">
        {isUploadImage && imageUrls.length === 0 && (
          <InputImage progress={imgProgress} onRemove={handleRemoveImages} />
        )}
        {imageUrls.map((imgUrl: string) => (
          <InputImage key={imgUrl} url={imgUrl} onRemove={handleRemoveImages} />
        ))}
      </div>
    );
  }
  return null;
};

export default ImageList;
