import cs from 'classnames';
import styles from './icon.module.less';
import { HTMLAttributes } from 'react';

export default function Icon({
  name,
  className,
  size = 'small',
  ...others
}: {
  name: string;
  /**
   * small = 1rem;
   * medium: 20px;
   */
  size?: 'small' | 'medium';
} & HTMLAttributes<HTMLSpanElement>) {
  return (
    <i data-size={size} className={cs('iconfont', name, styles.icon, className)} {...others} />
  );
}
