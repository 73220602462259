/*
 * @Author: wubo
 * @Date: 2023-07-21 14:08:16
 * @Description:
 * @LastEditTime: 2024-01-04 21:25:53
 * @LastEditors: zuoyuxing001
 */
import { Button, Result } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const handleBackHome = () => {
    navigate(`/${location.search}`);
  };

  return (
    <Result
      status="warning"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={handleBackHome}>
          {t('pages.errorPage.backHome')}
        </Button>
      }
    />
  );
};

export default ErrorPage;
