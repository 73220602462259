// 更多下拉
export enum MoreDropDownEnum {
  Presention = 'presention',
  Writing = 'writing',
  Chat = 'chat',
  Move = 'move',
  Del = 'del',
}

// 筛选类型-我创造或者我上传的
export enum ScreenTypeEnum {
  Uploaded = 'manual', // 我上传的
  Generated = 'ai', // 我创造的
}

// 筛选类型-文件类型
export enum ScreenEnum {
  All = 'all',
  Pdf = 'pdf',
  Ppt = 'ppt',
  Pptx = 'pptx',
  Presentation = 'presentation',
  Doc = 'doc',
  Docx = 'docx',
  Image = 'image',
  Writing = 'writing',
  Folder = 'folder',
  Gdrive = 'gdrive',
}

// 新增下拉
export enum NewBtnDorpDownEnum {
  Chatwithdoc = 'chatwithdoc',
  Presention = 'presention',
  Image = 'image',
  Writing = 'writing',
  Folder = 'folder',
  Gdrive = 'gdrive',
}

// 列表-action类型
export enum ActionBtnEnum {
  img = 'img',
  svg = 'svg',
  system = 'system',
  dropdown = 'dropdown',
}

// 列表-actionKey
export enum ActionBtnKey {
  history = 'history',
  pre = 'pre',
  more = 'more',
}

// 卡片-按钮下拉类型
export enum CardMoreDropDownEnum {
  Rename = 'rename',
  Edit = 'edit',
  Share = 'share',
  Export = 'export',
  Delete = 'delete',
}

// 多选框枚举
export enum CheckBoxEnum {
  All = 'all', // 全选
  Indeter = 'indeter',
  No = 'no',
}

// icon图标类型
export enum IconTypeEnum {
  System = 'system', // 自定义封装的
  Svg = 'svg', // 自定义封装的
  Img = 'img',
}

// 下拉菜单按钮位置
export enum DropDownPositon {
  Top = 'top', // 横线上
  Bottom = 'bottom', // 横线下
}
