import { useCallback, useEffect, useState } from 'react';
import {
  chatCreateUsingPOST,
  createNewChat,
  getHomeQuestionList,
  upsertWritingDoc,
} from '@/services';
import { QuestionEnum } from './enum';
import { GetTempListParams, ListItem, QuestionListData, QuestionListRes } from './types';
import { useChatStore, useUserStore, useWriteStore } from '@/store';
import useAutoSendStore from '@/store/autoSend';
import { ResponseType, UpsertDocResult } from '@/type';
import { AB_EXPERIMENTS, RESPONSE_DATA_CODE } from '@/common/config';
import { message } from 'antd';
import { useGlobalModal } from '@/layout/BasicLayout';
import { basicChatModelName, gpt4oName } from '@/common/model';
import { useDA, useSundry } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import axios from 'axios';

const source = axios.CancelToken.source();

const imageMap = {
  [QuestionEnum.Chat]: 'popai-chat',
  [QuestionEnum.Doc]: 'popai-wendang-xian',
  [QuestionEnum.Creation]: 'popai-presentation',
  [QuestionEnum.Writing]: 'popai-a-AIwriting',
};

const imageMapNew = {
  [QuestionEnum.Chat]: 'popai-chat',
  [QuestionEnum.Doc]: 'HomeV2Doc',
  [QuestionEnum.Creation]: 'HomeV2Ppt',
  [QuestionEnum.Writing]: 'HomeV2Writing',
};

export const useList = () => {
  const [list, setList] = useState<ListItem[]>([]);
  const [listLoading, setListLoading] = useState(false);
  const { setChannelId, setDocInitContent, setDocTitle } = useWriteStore();
  const { setAutoChatOption, setAutoChatSend, setAutoPptOption, setAutoPptSend } =
    useAutoSendStore();
  const { setChannel, setSendOptions, setHiresImages } = useChatStore();
  const { checkLoginStatus, createComplete } = useGlobalModal();
  const { sendDAEvent } = useDA();
  const navigate = useNavigate();
  const [creatingLoading, setCreatingLoading] = useState(false);
  const { experiment } = useSundry();
  const mainpageSugFeature = experiment(AB_EXPERIMENTS.MAINPAGE_SUG);
  useEffect(() => {
    mainpageSugFeature !== undefined && getData();
  }, [mainpageSugFeature]);

  const getTempList = useCallback(
    (params: GetTempListParams = {} as GetTempListParams) => {
      const {
        setChannelId,
        // setAutoChatOption,
        // setAutoChatSend,
        setAutoPptOption,
        setAutoPptSend,
        setDocInitContent,
        setDocTitle,
        setChannel,
        setSendOptions,
      } = params;
      const { guestToken } = useUserStore.getState();
      // 四种卡片类型配置，创建 channel 逻辑不同
      const tempList: ListItem[] = [
        // {
        //   type: QuestionEnum.Chat,
        //   icon: imageMap[QuestionEnum.Chat],
        //   async go(params: ListItem) {
        //     if (params.content) {
        //       try {
        //         setCreatingLoading(true);
        //         const res = await createNewChat<ResponseType<Channel>>({
        //           ...params.content,
        //           searchSwitch: true,
        //         });
        //         if ((params as any).sendRequest) {
        //           setAutoChatOption({
        //             ...(params as any).sendRequest,
        //             prompt: (params as any).sendRequest.message,
        //             searchSwitch: true,
        //           });
        //         }
        //         setAutoChatSend(true);
        //         setChannel({});
        //         return {
        //           type: 'link',
        //           href: `/chat/${res.data.channelId}`,
        //         };
        //       } catch (err: any) {
        //         if (
        //           err?.response?.data?.code === RESPONSE_DATA_CODE.UsageExceedsLimit &&
        //           guestToken
        //         ) {
        //           message.error(err?.response?.data?.message);
        //         }
        //       } finally {
        //         setCreatingLoading(false);
        //       }
        //     }
        //   },
        // },
        {
          type: QuestionEnum.Doc,
          icon: mainpageSugFeature ? imageMapNew[QuestionEnum.Doc] : imageMap[QuestionEnum.Doc],
          ...(mainpageSugFeature ? { title: t('components.homeCard.docTitle') } : {}),
          ...(mainpageSugFeature ? { description: t('components.homeCard.docDesc') } : {}),
          async go(params: ListItem) {
            if (params.content) {
              try {
                setCreatingLoading(true);
                const res = await chatCreateUsingPOST<
                  ResponseType<{
                    md5: string;
                    channel_id: string;
                    channel_name: string;
                    model: string;
                  }>
                >({
                  ...params.content,
                  searchSwitch: true,
                  channelType: 'ONE_CHAT',
                });

                const createOneChatParams = {
                  channelId: res.data.channel_id,
                  roleEnum: 'SUMMARYPROMPT',
                  docs: [
                    {
                      file_name: params.content.fileName,
                      file_type: params.content.fileContentType,
                      file_page_count: 1,
                      md5: params.content.md5,
                    },
                  ],
                  channelType: 'ONE_CHAT',
                  searchSwitch: true,
                };
                await createNewChat(createOneChatParams);
                setSendOptions({
                  channelId: res.data.channel_id,
                  roleEnum: 'SUMMARYPROMPT',
                  message: '',
                  prompt: '',
                  docs: [
                    {
                      file_name: params.content.fileName,
                      file_type: params.content.fileContentType,
                      file_page_count: 1,
                      md5: params.content.md5,
                    },
                  ],
                  channelType: 'ONE_CHAT',
                  searchSwitch: true,
                  defaultOpenGUI: true,
                });
                setChannel({ model: res?.data?.model, channelId: res?.data?.channel_id });
                setHiresImages([]);
                return {
                  type: 'link',
                  href: `/chat/${res.data.channel_id}`,
                };
              } catch (err: any) {
                if (
                  err?.response?.data?.code === RESPONSE_DATA_CODE.UsageExceedsLimit &&
                  guestToken
                ) {
                  message.error(err?.response?.data?.message);
                }
              } finally {
                setCreatingLoading(false);
              }
            }
          },
        },
        {
          type: QuestionEnum.Creation,
          icon: mainpageSugFeature
            ? imageMapNew[QuestionEnum.Creation]
            : imageMap[QuestionEnum.Creation],
          ...(mainpageSugFeature ? { title: t('components.homeCard.pptTitle') } : {}),
          ...(mainpageSugFeature ? { description: t('components.homeCard.pptDesc') } : {}),
          async go(params: ListItem) {
            if (params.content) {
              try {
                setCreatingLoading(true);
                const res: any = await createNewChat({
                  ...params.content,
                  searchSwitch: true,
                });
                setAutoPptOption({
                  pptMessage: params.content?.message || '',
                });
                localStorage.setItem(
                  'autoPptState',
                  JSON.stringify({
                    pptMessage: params.content?.message || '',
                  }),
                );
                setAutoPptSend(true);
                setChannel({});

                return {
                  type: 'link',
                  href: `/chat/${res.data.channelId}`,
                };
              } catch (e) {
                console.error(e);
              } finally {
                setCreatingLoading(false);
              }
            }
          },
        },
        {
          type: QuestionEnum.Writing,
          icon: mainpageSugFeature
            ? imageMapNew[QuestionEnum.Writing]
            : imageMap[QuestionEnum.Writing],
          ...(mainpageSugFeature ? { title: t('components.homeCard.writingTitle') } : {}),
          ...(mainpageSugFeature ? { description: t('components.homeCard.writingDesc') } : {}),
          async go(params: ListItem) {
            if (params.content) {
              try {
                const message = params.content?.message || '';
                const res = await upsertWritingDoc<ResponseType<UpsertDocResult>>({
                  name: message,
                  content: '',
                  sourceEnum: 'RECOMMEND',
                });
                if (res.data?.channelId && res.data?.docId) {
                  setChannelId(res.data?.channelId);
                  setDocInitContent('');
                  setDocTitle(message);

                  localStorage.setItem(
                    'writingState',
                    JSON.stringify({
                      ignoreGetDoc: true,
                      automaticSend: true,
                      docTitle: message,
                      ...params.content,
                      channelId: res.data?.channelId,
                    }),
                  );

                  return {
                    type: 'link',
                    href: `/ai-writing/${res.data?.docId}`,
                    params: {
                      ignoreGetDoc: true,
                      automaticSend: true,
                      ...params.content,
                    },
                  };
                }
              } catch (e) {
                console.error(e);
              } finally {
                setCreatingLoading(false);
              }
            }
          },
        },
      ];
      return tempList;
    },
    [mainpageSugFeature],
  );

  const getData = useCallback(async () => {
    if (mainpageSugFeature) {
      source.cancel();
      listLoading && setListLoading(false);
      const tempList = getTempList();
      setList([...tempList]);
      return;
    }
    setListLoading(true);
    const res: QuestionListRes = await getHomeQuestionList(source);
    if (res.data) {
      const tempList = getTempList({
        setChannelId,
        setAutoChatOption,
        setAutoChatSend,
        setAutoPptOption,
        setAutoPptSend,
        setDocInitContent,
        setDocTitle,
        setChannel,
        setSendOptions,
      });
      const nowList = tempList.map((item) => {
        const one: QuestionListData | undefined = res.data.find((itm) => itm.type === item.type);
        const args = {
          title: one?.title || '',
          content: {},
          description: one?.description || '',
          sendRequest: {},
        };
        if (one && one.content) {
          try {
            args.content = JSON.parse(one.content);
          } catch (error) {
            args.content = {};
          }
        }
        if (one && one.content) {
          try {
            args.sendRequest = JSON.parse(one.sendRequest);
          } catch (error) {
            args.sendRequest = {};
          }
        }
        return {
          ...item,
          ...args,
        };
      });
      setList([...nowList]);
    }
    setListLoading(false);
  }, [mainpageSugFeature, getTempList]);

  const handleCheckLogin = useCallback(
    (channelType: QuestionEnum) => {
      if (channelType === QuestionEnum.Creation)
        return checkLoginStatus({ type: 'AiCreation', model: gpt4oName });
      if (channelType === QuestionEnum.Doc)
        return checkLoginStatus({ type: '', model: basicChatModelName });
      return checkLoginStatus({ type: '', model: basicChatModelName });
    },
    [checkLoginStatus],
  );

  const cardClickHandler = useCallback(
    async (item: ListItem) => {
      if (creatingLoading) return;
      if (mainpageSugFeature) {
        sendDAEvent('RecommendedQuestions_Click_new', {
          question_type: item.type,
          question_description: item.description,
        });
        if (item.type === QuestionEnum.Doc) {
          navigate(`/ai-reader${location?.search}`);
        } else if (item.type === QuestionEnum.Creation) {
          navigate(`/ai-presentation${location?.search}`);
        } else if (item.type === QuestionEnum.Writing) {
          navigate(`/ai-writing${location?.search}`);
        }
        return;
      }
      setCreatingLoading(true);
      if (item.go) {
        if (!handleCheckLogin(item.type)) {
          setCreatingLoading(false);
          return;
        }
        const res = await item.go(item);
        if (res?.type === 'link' && res.href) {
          sendDAEvent('RecommendedQuestions_Click', {
            question_type: item.type,
            question_id: res.href,
            question_description: item.description,
          });
          createComplete();
          if (res.params) {
            navigate(res.href, {
              state: res.params,
            });
          } else {
            navigate(res.href);
          }
        }
      }
      setCreatingLoading(false);
    },
    [createComplete, creatingLoading, handleCheckLogin, navigate, sendDAEvent, mainpageSugFeature],
  );

  return {
    list,
    setList,
    listLoading,
    cardClickHandler,
  };
};
