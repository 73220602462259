import React, { useState } from 'react';
import { Select, Form, FormInstance } from 'antd';
import CustomIcon from '@/components/CustomIcon';
import type { AdvanceConfigSettingItem } from '@/type';
import { isFunction } from 'lodash-es';
import { useDA } from '@/hooks';
import { useChatStore } from '@/store';
import './index.less';
import { getChatType } from '@/common/helpers/chatHelper';

interface SettingSelectItemProps {
  label?: string;
  name: string;
  options?: AdvanceConfigSettingItem[];
  form?: FormInstance;
  disabled?: boolean;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
  optionRender?: (option: AdvanceConfigSettingItem) => React.ReactNode;
  dropdownRender?: (menu: React.ReactElement) => React.ReactElement;
  changeHandle?: (event: { value: string; option: AdvanceConfigSettingItem }) => void;
  onDropdownVisibleChange?: (open: boolean) => void;
}
const SettingSelectItem: React.FC<SettingSelectItemProps> = (props) => {
  const {
    label,
    name,
    options,
    form,
    disabled,
    optionRender,
    dropdownRender,
    changeHandle,
    onDropdownVisibleChange,
    placement,
  } = props;

  const { sendDAEvent } = useDA();
  const { channel } = useChatStore();

  //上一次选择的免费选项
  const [lastFreeValue, setLastFreeValue] = useState<string | undefined>(
    options?.find((item: AdvanceConfigSettingItem) => item.default)?.code,
  );

  const onChange = (
    value: string,
    option: AdvanceConfigSettingItem | AdvanceConfigSettingItem[],
  ) => {
    //如果触发付费卡点，则回到上一次免费选项
    if ((option as AdvanceConfigSettingItem).forbidForPay) {
      form?.setFieldsValue({ [name]: lastFreeValue });
    } else {
      setLastFreeValue(value);
    }
    changeHandle?.({ value, option: option as AdvanceConfigSettingItem });
    sendDAEvent('TemplateButtonSetting_Change', {
      channelid: channel?.channelId || '',
      chattype: channel?.channelId ? getChatType(channel) : '',
      button_type: name,
      to: value,
    });
  };

  return (
    <div className="ppt-setting-item">
      <div className="ppt-setting-item-label">{label}</div>
      <Form.Item noStyle name={name}>
        <Select
          defaultActiveFirstOption={false}
          disabled={disabled}
          className="ppt-setting-select"
          popupClassName="ppt-setting-select-popup upgrade"
          popupMatchSelectWidth={false}
          placement={placement}
          fieldNames={{ label: 'name', value: 'code' }}
          suffixIcon={<CustomIcon className="arrow-icon" type="arrowBottomSquare" />}
          options={options}
          onChange={onChange}
          dropdownRender={dropdownRender}
          onDropdownVisibleChange={onDropdownVisibleChange}
          optionRender={(option) => {
            if (isFunction(optionRender)) {
              return optionRender(option.data as AdvanceConfigSettingItem);
            }
            return option.label;
          }}
        />
      </Form.Item>
    </div>
  );
};

export default SettingSelectItem;
