import { useUserPromptStore } from '@/store';
import { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SuggestionContainer from '@/components/InputControl/Suggestion/Container';
import { UserPromptFormModal } from '@/components/UserPrompt';
import PromptItem from './PromptItem/PromptItem';
import UserPromptWelcome from './Welcome';
import styles from './index.module.less';
import { UserPrompt } from '@/type';
import { Loading } from '@/components/Loading';
import Icon from '@/components/Icon';
import { InputContrlProps } from '../../types';
import { useDA } from '@/hooks';

const UserPromptSuggestionControl = memo(
  forwardRef<
    HTMLDivElement,
    InputContrlProps & {
      onClose: () => void;
      hint: string;
      items: UserPrompt[];
      displayCount?: number;
      addOnTop?: boolean;
    }
  >(({ onSuggestion, active, onClose, hint, items, displayCount, addOnTop = false }, ref) => {
    const { t } = useTranslation();

    const [addModalOpen, setAddModalOpen] = useState(false);
    const { initialized, total } = useUserPromptStore((state) => ({
      initialized: state.initialized,
      total: state?.total,
    }));
    const listStyle = useMemo(() => {
      if (displayCount) {
        return {
          maxHeight: 48 * displayCount,
        };
      }
      return {};
    }, [displayCount]);
    const showWelcome = initialized && total === 0;
    const { sendDAEvent } = useDA();

    const handleAddPromptClick = useCallback(() => {
      sendDAEvent('AddMyPrompt', {
        button_name: 'AddMyPrompt',
      });
      setAddModalOpen(true);
    }, [sendDAEvent]);

    const onItemClick = useCallback(
      (value: string) => {
        onSuggestion && onSuggestion(value);
      },
      [onSuggestion],
    );

    const closeModel = useCallback((): void => {
      setAddModalOpen(false);
    }, []);

    return (
      <SuggestionContainer
        title={showWelcome ? '' : t('components.userPrompt.suggestionTitle')}
        closable={true}
        onClose={onClose}
        active={!!active}
        ref={ref}
        interpreteClick={true}
      >
        {initialized ? (
          <>
            {addOnTop && (
              <div className={styles.footer}>
                <a onClick={handleAddPromptClick} className={styles.add_link}>
                  <Icon name="popai-add" className={styles.add_icon}></Icon>
                  {t('components.userPrompt.addMyPrompt')}
                </a>
              </div>
            )}
            {showWelcome ? (
              <UserPromptWelcome />
            ) : (
              <div className={styles.list} style={listStyle}>
                {items.map((item) => (
                  <PromptItem
                    key={item.promptId}
                    {...item}
                    onClick={onItemClick}
                    highlight={hint}
                  ></PromptItem>
                ))}
              </div>
            )}
            {!addOnTop && (
              <div className={styles.footer}>
                <a onClick={handleAddPromptClick} className={styles.add_link}>
                  <Icon name="popai-add" className={styles.add_icon}></Icon>
                  {t('components.userPrompt.addMyPrompt')}
                </a>
              </div>
            )}
          </>
        ) : (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
        {addModalOpen && (
          <UserPromptFormModal
            onCancel={closeModel}
            onSubmit={closeModel}
            getContainer={() => (ref as React.RefObject<HTMLDivElement>)?.current as HTMLDivElement}
          />
        )}
      </SuggestionContainer>
    );
  }),
);

export default UserPromptSuggestionControl;
