import axios from '@/common/request';
import { ScreenEnum, ScreenTypeEnum } from '@/pages/WorkSpace/typing';

const formatParams = <T>(params: T) => {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  return paramArr.join('&') || '';
};
// 获取List列表
export const getWorkspaceFilelist = async <T>(params: {
  page: number;
  pagesize: number;
  generate_type: ScreenTypeEnum;
  minutesOffset: number;
  types?: string;
}) => {
  const result = await axios.get<T>(`/api/v1/workspace/fileList?${formatParams(params)}`);
  return result.data;
};

// 查询history列表
export const getHistoryList = async <T>(params: {
  md5: string;
  type: ScreenEnum;
  minutesOffset: number;
}) => {
  const result = await axios.get<T>(
    `/api/v1/workspace/fileRelatedChannelList?${formatParams(params)}`,
  );
  return result.data;
};
