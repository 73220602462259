import { useState, useEffect, useMemo } from 'react';
import CustomIcon from '@/components/CustomIcon';
import { useCommonStore } from '@/store';
import { useDA, useGASendEvent, useSundry } from '@/hooks';
import { Select } from 'antd';
import CirclePng from '@/assets/circle.png';
import { getDocSummaryTips } from '@/services';
import { isEmpty } from 'lodash-es';
import QuestionPopover from '../QuestionPopover';
import { useTranslation } from 'react-i18next';

import type { ResponseType, TipsData, DocTips, Language, Channel } from '@/type';

import './index.less';
import { AB_EXPERIMENTS } from '@/common/config';
import { getChatType } from '@/common/helpers/chatHelper';

type FileTypeMap = Record<
  string,
  { summary: TipsData[] | null; presentation: TipsData[] | null; suggestions: TipsData[] | null }
>;
interface FileTypeTipProps {
  channel?: Channel;
  outputLanguage?: string; // one chat内不传，不展示
  canCreatePPT?: boolean; // one chat内不允许enhance
  onLanguageChange?: (val: string) => void; // one chat内不切换语言
  onChooseQuestion: (item: TipsData, type: string) => void;
}

export const outputDefaultValue = 'Auto';

const FileTypeTip: React.FC<FileTypeTipProps> = (props) => {
  const { channel, outputLanguage, canCreatePPT = true, onChooseQuestion } = props;

  const [fileTypeTipsMap, setFileTypeTipsMap] = useState<FileTypeMap>({});
  const [fileTypeSelectOpen, setFileTypeSelectOpen] = useState(false);
  const [selectFileType, setSelectFileType] = useState('General');
  const [openPopoverType, setOpenPopoverType] = useState<string | null>(null);

  const { languageInfo } = useCommonStore();
  const { sendEvent } = useGASendEvent();
  const { preparePropertyFor, sendDAEvent } = useDA();

  const { experiment } = useSundry();
  const isUpgrade = experiment(AB_EXPERIMENTS.UPGRADE);

  const { t } = useTranslation();

  useEffect(() => {
    preparePropertyFor(
      'Chat_SendMessage',
      'file_type_choosed',
      selectFileType.toLocaleLowerCase(),
      false,
    );
  }, [preparePropertyFor, selectFileType]);

  const outputLangOptions = useMemo(() => {
    const recommend = (languageInfo?.recommend || []).map((item: Language) => ({
      ...item,
      value: item.languageEnglish,
      label: item.languageLabel,
      title: `${item.languageEnglish} - ${item.languageLabel}`,
    }));
    const all = (languageInfo?.all || []).map((item: Language) => ({
      ...item,
      value: item.languageEnglish,
      label: item.languageLabel,
      title: `${item.languageEnglish} - ${item.languageLabel}`,
    }));
    return [
      {
        label: `${t('common.Recommend')}`,
        options: [
          {
            value: outputDefaultValue,
            label: `${t('common.auto')}`,
            des: `${t('common.follow')}`,
          },
          ...recommend,
        ],
      },
      {
        label: `${t('common.all')}`,
        options: all,
      },
    ];
  }, [languageInfo]);

  useEffect(() => {
    const fetchSummaryTips = async () => {
      const res = await getDocSummaryTips<ResponseType<DocTips[]>>();
      const fileTypeMap = res.data.reduce<FileTypeMap>((acc, current) => {
        if (current.fileType) {
          acc[current.fileType] = {
            summary: current?.summary || null,
            presentation: current?.presentation || null,
            suggestions: current?.suggestions || null,
          };
        }
        return acc;
      }, {});
      setFileTypeTipsMap(fileTypeMap);
      setSelectFileType(res?.data[0]?.fileType);
    };
    fetchSummaryTips();
  }, []);

  const handleDropdownVisibleChange = (open: boolean) => {
    setFileTypeSelectOpen(open);
  };

  const handleChooseQuestion = (item: TipsData, type: string) => {
    sendEvent('ClickSuggestPrompt', {
      channelId: channel?.channelId,
      channelName: channel?.channelName,
      fileMd5: channel?.md5,
      fileType: selectFileType,
      promptType: openPopoverType,
      docPrompt: item.title,
    });
    sendDAEvent('DocChat_TopButton_Click', {
      chattype: getChatType(channel as Channel),
      channelid: channel?.channelId,
      file_type: selectFileType,
      button_type: openPopoverType,
      doc_prompt: item.title,
    });
    preparePropertyFor('Chat_SendMessage', 'message_type', `${type}_prompt`);
    setOpenPopoverType(null);
    onChooseQuestion?.(item, type);
  };

  const renderQuestionPopover = (type: string, items: TipsData[] | null, btnText: string) => {
    if (!items) {
      return null;
    }

    const isOpen = openPopoverType === type;

    const handleOpenChange = (visible: boolean) => {
      if (visible) {
        setOpenPopoverType(type);
        setFileTypeSelectOpen(false);
      } else if (isOpen) {
        setOpenPopoverType(null);
      }
    };

    return (
      <>
        <QuestionPopover
          open={isOpen}
          onOpenChange={handleOpenChange}
          items={items}
          btnText={btnText}
          type={type}
          btnIcon={type === 'presentation' ? 'popai-presentation' : 'popai-a-summary1'}
          onChooseQuestion={(val) => handleChooseQuestion(val, type)}
        />
        {type === 'suggestions' && (
          // 设置key保障动画每次loading
          <div className="icon-container" key={selectFileType}>
            <div className="loading-icon">
              <img src={CirclePng} alt="loading" />
            </div>
            <div className="finish-icon">
              <CustomIcon type="finish" />
            </div>
          </div>
        )}
      </>
    );
  };

  if (isEmpty(fileTypeTipsMap)) {
    return null;
  }
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleChange = (val: string) => {
    sendDAEvent('DocChat_ChooseFileTypeButton_Click', {
      channelid: channel?.channelId,
      chattype: getChatType(channel as Channel),
      from: selectFileType,
      to: val,
    });
    setSelectFileType(val);
  };

  return (
    <div className="pdf-opertate-container">
      <Select
        className="file-type-select"
        popupClassName="pdf-opertate-common-popup file-type-select-popup"
        suffixIcon={<CustomIcon type={!fileTypeSelectOpen ? 'popai-down' : 'popai-up'} />}
        variant="borderless"
        dropdownRender={(menu) => (
          <>
            <div className="select-header">{t('pages.share.chooseFileType')}</div>
            {menu}
          </>
        )}
        open={fileTypeSelectOpen}
        value={selectFileType}
        onChange={handleChange}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        options={Object.keys(fileTypeTipsMap).map((item) => {
          return {
            value: item,
            label: item,
          };
        })}
        getPopupContainer={() =>
          (document.getElementsByClassName('chat-controller')[0] as HTMLElement) ||
          (document.getElementsByClassName('chat-controller2')[0] as HTMLElement)
        }
        onClick={(e) => handleClick(e)}
      />
      <hr />
      <div className="pdf-opertate-btn-conatiner">
        {renderQuestionPopover(
          'summary',
          fileTypeTipsMap?.[selectFileType]?.summary,
          t('common.summary'),
        )}
        {canCreatePPT &&
          renderQuestionPopover(
            'presentation',
            fileTypeTipsMap?.[selectFileType]?.presentation,
            t('common.presentation'),
          )}
        {renderQuestionPopover(
          'suggestions',
          fileTypeTipsMap?.[selectFileType]?.suggestions,
          t('common.suggestions'),
        )}
      </div>
      {outputLanguage && !isUpgrade && (
        <div className="output-lang">
          <div className="output-lang-label">{t('components.send.output')}</div>
          <Select
            value={outputLanguage}
            options={outputLangOptions}
            popupClassName="output-language-select-popup"
            suffixIcon={<CustomIcon className="chat-select-suffix" type="arrowBottomSquare" />}
            onChange={props?.onLanguageChange}
            className="output-lang-select"
            placement="topRight"
            onClick={(e) => handleClick(e)}
            optionRender={(option) => {
              return (
                <div>
                  {option.data.value === outputDefaultValue ? (
                    <>
                      {/* {option.data.value} */}
                      {t('common.auto')}
                      <div className="auto-des">{option.data.des}</div>
                    </>
                  ) : (
                    option.data.title
                  )}
                </div>
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FileTypeTip;
