export const fieldReplace = (prompt: string, value: string) => {
  return prompt?.replace(/\[\s*([^[\]]*?)\s*\]/g, function (match, key) {
    if (key === 'PROMPT') {
      return value ?? '';
    } else if (key === 'TARGETLANGUAGE') {
      return 'Auto';
    }
    return match;
  });
};
