import CustomIcon from '@/components/CustomIcon';
import { ListItem } from '../../types';
import { debounce } from 'lodash-es';
import './index.less';

interface Props {
  item: ListItem;
  onClick: (data: ListItem) => void;
}

/** card单项卡片 */
const Card = (props: Props) => {
  const { item, onClick } = props;
  const { title, description } = item;

  const clickHandler = debounce(async () => {
    onClick && onClick(item);
  }, 300);

  return (
    <div className="card" onClick={clickHandler}>
      <div className="card__title">
        <div className="card__title-text">
          <CustomIcon type={item.icon} className="card__title-icon" />
          <span>{title}</span>
        </div>
        <div className="isRtlIcon_language_ar">
          <CustomIcon type="popai-right" className="card__title-arrow" />
        </div>
      </div>
      <div className="card__desc" title={description}>
        {description}
      </div>
    </div>
  );
};

export default Card;
