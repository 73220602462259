/*
 * @Author: wubo
 * @Date: 2023-09-26 10:52:23
 * @Description:
 * @LastEditTime: 2024-01-04 20:00:53
 * @LastEditors: zuoyuxing001
 */
import { useEffect, useMemo, useState, useRef } from 'react';
import { Spin, Space } from 'antd';
import { aiModelMap } from '@/common/config';
import { useUserStore, useChatStore, useCommonStore } from '@/store';
import { getTplPreviewDetail } from '@/services';
// import ModelSelect from '@/components/ModelSelect';
import CustomIcon from '@/components/CustomIcon';
import ChatHeader from '@/components/ChatHeader';
import Messages from '@/components/Messages';
import ScaleContainer from '@/components/ScaleContainer';
import PresentationView from '@/components/PresentationViewV2';
import { useTranslation } from 'react-i18next';
import ScrollToBottom from 'react-scroll-to-bottom';

import classNames from 'classnames';
import type {
  ResponseType,
  MessageItem,
  ShareDetailData,
  PromptTemplate,
  CreateChannelOptions,
} from '@/type';
import type { PresentationContent } from '@/components/PresentationViewV2';

import './index.less';
import { usePPTTheme } from '@/pages/Chat/components/ChatViewWithPPT/useChatViewWithPPT';

// import { shouldUserUseNewHome } from '@/common/gray';

interface TemplatePreviewProps {
  previewKey?: string;
  template?: PromptTemplate;
  onClosePreview?: () => void;
  onMessageSend?: (options?: CreateChannelOptions) => Promise<any>;
  isHome?: boolean; // 首页改版后，是否是首页的预览
}

const TemplatePreview: React.FC<TemplatePreviewProps> = (props) => {
  const { previewKey, template, onClosePreview, onMessageSend } = props;
  const [messages, setMessages] = useState([] as MessageItem[]);
  const [model, setModel] = useState('');
  const [fileTypeEnum, setFileTypeEnum] = useState('');
  const [channelName, setChannelName] = useState('');
  const [loading, setLoading] = useState(false);
  const [slides, setSlides] = useState<PresentationContent>({});

  const { t } = useTranslation();
  const { userInfo } = useUserStore();
  const { setJumpSlides } = useChatStore();
  const { outputLanguage } = useCommonStore();
  const templatePreviewRef = useRef<HTMLDivElement>(null);

  const { setThemeCssFromRemote } = usePPTTheme();

  const avatarInfo = useMemo(
    () => ({
      src: null,
      aiInfo: {
        ...(aiModelMap[model] || {}),
        style: {
          ...(aiModelMap[model]?.style || {}),
          fontSize: '26px',
        },
      },
      humanInfo: {
        name: 'user',
        style: {
          background: 'rgba(0, 0, 0, 0.25)',
          fontSize: '22px',
        },
      },
    }),
    [model],
  );

  const fetchShareChatDetail = async (isLoading = true) => {
    isLoading && setLoading(true);

    const options = !template?.mediaType
      ? {
          baseURL: import.meta.env.VITE_APP_TPL_PREVIEW_BASE_URL,
        }
      : {};

    try {
      const res = await getTplPreviewDetail<ResponseType<ShareDetailData>>(
        {
          shareKey: previewKey as string,
        },
        options,
      );
      const data = res.data;
      setMessages(data.messageList);
      data.model && setModel(data.model);
      data.fileTypeEnum && setFileTypeEnum(data.fileTypeEnum);
      data.slides && setSlides(JSON.parse(data?.slides?.[0]?.content ?? '{}'));
      setChannelName(data.channelName);
    } catch (err) {
    } finally {
      isLoading && setLoading(false);
    }
  };

  const handleMessageSend = () => {
    if (template?.mediaType === 'ppt' && !template?.infoCollect) {
      setJumpSlides(true);
    }

    onMessageSend?.({
      prompt: template?.prompt ?? '',
      isNewChat: true,
      richMessageId: null,
      action: null,
      isGeneratePpt: false,
      isSlidesChat: false,
      isPresetPpt: true,
      language: outputLanguage?.languageEnglish,
    });
  };

  useEffect(() => {
    if (previewKey) {
      fetchShareChatDetail();
    }
  }, [previewKey]);

  useEffect(() => {
    return () => onClosePreview?.();
  }, []);

  useEffect(() => {
    if (template?.themeName) {
      setThemeCssFromRemote(template?.themeName);
    }
  }, [template?.themeName]);

  const headerExtraOperateRender = () => (
    <Space size={12}>
      {/* {!!model && (
        <ModelSelect
          value={model}
          disabled
          style={{ height: '28px', lineHeight: '28px', background: 'rgba(0, 0, 0, 0.06)' }}
        />
      )} */}
      <button className="close-button" onClick={() => onClosePreview?.()}>
        <CustomIcon type="close" />
      </button>
    </Space>
  );

  const previewContentRender = () => {
    switch (fileTypeEnum) {
      case 'ppt':
        return (
          <ScaleContainer className="ppt-template-container ppt-view">
            {(scale) => (
              <PresentationView
                options={{
                  content: slides,
                  scale,
                  showShadow: true,
                  showLogo: false,
                  borderRadius: 16,
                  cardGap: 24 / scale,
                  theme: template?.themeName,
                  innerModel: userInfo?.internal === 1,
                  container: templatePreviewRef?.current,
                }}
              />
            )}
          </ScaleContainer>
        );
      default:
        return (
          <ScrollToBottom mode="top" initialScrollBehavior="auto">
            <Messages avatarInfo={avatarInfo} messages={messages} isReady={true} />
          </ScrollToBottom>
        );
    }
  };

  return (
    <div className="template-preview-container">
      <ChatHeader
        type={template?.mediaType || 'chat'}
        title={
          <>
            <span className="title-highlight">{t('common.preview')}</span>
            {channelName && ` | ${channelName}`}
          </>
        }
        extraOperateRender={headerExtraOperateRender}
      />
      <Spin spinning={loading}>
        <div
          className={classNames({
            'template-preview-dialog': true,
          })}
          ref={templatePreviewRef}
        >
          {previewContentRender()}
        </div>
      </Spin>
      {template?.mediaType === 'ppt' && !template?.infoCollect && (
        <div className="template-preview-footer">
          <button onClick={handleMessageSend}>{t('components.template.templateBtn')}</button>
        </div>
      )}
    </div>
  );
};

export default TemplatePreview;
