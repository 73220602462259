import React from 'react';
import { Select, Form, FormInstance } from 'antd';
import CustomIcon from '@/components/CustomIcon';
import type { AdvanceConfigSettingItem } from '@/type';
import { isFunction } from 'lodash-es';
import { useDA } from '@/hooks';
import { useChatStore } from '@/store';
import { getChatType } from '@/common/helpers/chatHelper';

import './index.less';

interface SettingSelectItemProps {
  label?: React.ReactNode | string;
  name: string;
  options?: AdvanceConfigSettingItem[];
  form?: FormInstance;
  disabled?: boolean;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
  optionRender?: (option: AdvanceConfigSettingItem) => React.ReactNode;
  dropdownRender?: (menu: React.ReactElement) => React.ReactElement;
  changeHandle?: (event: { value: string; option: AdvanceConfigSettingItem }) => void;
  onDropdownVisibleChange?: (open: boolean) => void;
}

const SettingSelectItem: React.FC<SettingSelectItemProps> = (props) => {
  const { sendDAEvent } = useDA();

  const {
    label,
    name,
    options,
    disabled,
    optionRender,
    dropdownRender,
    changeHandle,
    onDropdownVisibleChange,
    placement,
  } = props;
  const { channel } = useChatStore();

  const onChange = (
    value: string,
    option: AdvanceConfigSettingItem | AdvanceConfigSettingItem[],
  ) => {
    changeHandle?.({ value, option: option as AdvanceConfigSettingItem });
    sendDAEvent('WritingButtonSetting_Change', {
      channelid: channel?.channelId || '',
      chattype: channel?.channelId ? getChatType(channel) : '',
      button_type: name,
      to: value,
    });
  };

  return (
    <div className="writing-setting-item">
      <div className="writing-setting-item-label">{label}</div>
      <Form.Item noStyle name={name}>
        <Select
          defaultActiveFirstOption={false}
          disabled={disabled}
          className="writing-setting-select"
          popupClassName="writing-setting-select-popup"
          popupMatchSelectWidth={false}
          placement={placement}
          fieldNames={{ label: 'name', value: 'code' }}
          suffixIcon={<CustomIcon className="arrow-icon" type="arrowBottomSquare" />}
          options={options}
          onChange={onChange}
          dropdownRender={dropdownRender}
          onDropdownVisibleChange={onDropdownVisibleChange}
          optionRender={(option) => {
            if (isFunction(optionRender)) {
              return optionRender(option.data as AdvanceConfigSettingItem);
            }
            return option.label;
          }}
        />
      </Form.Item>
    </div>
  );
};

export default SettingSelectItem;
