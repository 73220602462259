import AddFileItemUi from '@/components/AddFileItemUi';
import { InputUploadFile } from '@/store/sendUpload';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Channel, EUploadScene } from '@/type';
import { basicChatModelName } from '@/common/model';
import UploadFromFiles from '@/components/UploadFromFiles';

function AddWorkspaceFiles({
  onAdd,
  className,
  multiSelection = true,
  channel,
  children,
  currentModel = basicChatModelName,
  type = 'normal',
}: {
  type?: EUploadScene[number];
  onAdd: (files: InputUploadFile[]) => void;
  multiSelection?: boolean;
  channel?: Channel;
  children?: React.ReactNode;
  currentModel?: string;
} & HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation();

  return (
    <UploadFromFiles
      type="chatInput"
      multiSelection={multiSelection}
      channel={channel}
      currentModel={currentModel}
      uploadScene={type}
      onAdd={onAdd}
    >
      <AddFileItemUi
        iconType="popai-a-workspace1"
        text={t('components.upload.addFromFiles')}
        className={className}
      >
        {children}
      </AddFileItemUi>
    </UploadFromFiles>
  );
}

export default AddWorkspaceFiles;
