import React, { ReactNode } from 'react';
import './PreviewModel.less';

interface Props {
  children: ReactNode;
  onClose: () => void;
}

/** 预览模版弹窗-弹框或者原来样式展示 */
export const PreviewModel: React.FC<Props> = (props) => {
  const { children, onClose } = props;
  return (
    <div className="preview-model-box" onClick={onClose}>
      <div className="preview-model-content" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};
