import axios from '@/common/request';
// import { message } from 'antd';
/**
 * 记录用户上传及选择图片的历史操作记录
 * @param params
 * @returns
 */
export async function editImg<T>(params: {
  channelId: string;
  imageUrl: string;
  newImg: boolean;
  nodeId: string;
}) {
  const result = await axios.post<T>('/api/v1/ppt/editImg', params);
  return result.data;
}
/**
 * 获取图片列表，列表中的数据有不同类型
 * @param params
 * @returns
 */
export async function getImgList<T>(params: {
  channelId: string;
  imageType: number;
  indexSize: number;
  nextIndex: number;
  layoutName?: string;
}) {
  const result = await axios.post<T>('/api/v1/ppt/project/img/list', params);
  return result.data;
}
/**
 * AI 搜图、生图
 * @param params
 * @returns
 */

export interface AISearchCreateImageParams {
  channelId: string;
  enhanceAction: string;
  nodeId: string; // 改动图片对应的json 里的node id
  previewPrompt?: string;
  type: number; //1: ai搜图；2:todo ai生图 prompt;3:todo ai生图 文生图
  // pptSearchEnhanceStyle: string;
  // pptSearchEnhanceDesc: string;
}
export async function aiSearchCreateImage<T>(params: AISearchCreateImageParams) {
  const result = await axios.post<T>('/api/v1/ppt/aiSearchCreateImg', params);
  return result.data;
}
