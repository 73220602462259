import { Button, Flex, Modal } from 'antd';
import { ChangeEvent, useCallback, useEffect, useRef, useState, memo } from 'react';
import cs from 'classnames';

import { useUserPromptStore } from '@/store';
import { UserPrompt } from '@/type';
import { useTranslation } from 'react-i18next';
import styles from './index.module.less';
import Icon from '@/components/Icon';
import { useDA } from '@/hooks';
import { MAX_USER_PROMPT_CONTENT_COUNT, MAX_USER_PROMPT_NAME_COUNT } from '@/common/config';
import TextSizeTip from '@/components/TextSizeTip/';
import TextOverflow from '@/components/TextOverflow';

const UserPromptFormModal = memo(
  ({
    onCancel,
    onSubmit,
    getContainer,
    className,

    promptId,
    name: nameProp,
    content: contentProp,
  }: {
    onCancel: () => void;
    onSubmit: (promise: Promise<void>) => void;
    getContainer?: HTMLElement | (() => HTMLElement) | string | false;
    className?: string;
  } & Partial<UserPrompt>) => {
    const [open, setOpen] = useState(true);
    const [name, setName] = useState<string>(nameProp || '');
    const [content, setContent] = useState<string>(contentProp || '');
    const [disableAddBtn, setDiableAddBtn] = useState(content === '');
    const [showNameInput, setShowNameInput] = useState(false);
    const nameInputRef = useRef<HTMLInputElement | null>(null);
    const contentInputRef = useRef<HTMLTextAreaElement | null>(null);
    const { t } = useTranslation();
    const { add, edit } = useUserPromptStore((state) => ({
      add: state.add,
      edit: state.edit,
    }));
    const { sendDAEvent } = useDA();

    /**
     * focus the name input after rerender
     */
    useEffect(() => {
      if (showNameInput && nameInputRef.current) {
        nameInputRef.current.focus();
      }
    }, [showNameInput]);

    const handleSubmitClick = useCallback(() => {
      let promise: Promise<void>;
      sendDAEvent('AddPrompt', {
        forward_source: 'click_add_prompt',
        button_name: 'AddPrompt',
      });
      if (promptId) {
        // edit
        promise = edit(promptId, { name: name.trim(), content });
      } else {
        // add
        promise = add({ name: name.trim(), content });
      }
      onSubmit(promise);
    }, [add, content, edit, name, onSubmit, promptId, sendDAEvent]);

    const handleInput = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
      if (event.target.value !== '') {
        setDiableAddBtn(event.target.value.length > MAX_USER_PROMPT_CONTENT_COUNT);
      } else {
        setDiableAddBtn(true);
      }
      setContent(event.target.value);
    }, []);

    const handleCustomizeClick = useCallback(() => {
      setShowNameInput(true);
    }, []);

    const handleNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    }, []);

    const handleConfirmClick = useCallback(() => {
      setShowNameInput(false);
    }, []);

    const afterOpenChange = useCallback((open: boolean) => {
      if (open && contentInputRef.current) {
        contentInputRef.current.focus();
        const length = contentInputRef.current.value.length;
        contentInputRef.current.setSelectionRange(length, length);
        contentInputRef.current.scrollTo({
          top: contentInputRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, []);

    const handleCancelClick = useCallback((): void => {
      setOpen(false);
      onCancel();
    }, [onCancel]);

    return (
      <Modal
        getContainer={getContainer}
        wrapClassName={className}
        classNames={{
          content: styles.dialog,
        }}
        centered
        width={720}
        afterOpenChange={afterOpenChange}
        open={open}
        mask={true}
        maskClosable={false}
        footer={[
          <div className={styles.buttons} key="form-footer">
            <Button className={styles.btn} onClick={handleCancelClick} key="cancel">
              {t('common.cancel')}
            </Button>
            <Button
              disabled={disableAddBtn}
              type="primary"
              className={styles.submit_btn}
              onClick={handleSubmitClick}
              key="submit"
            >
              {promptId ? t('common.confirm') : t('common.add')}
            </Button>
          </div>,
        ]}
        closeIcon={<Icon name="popai-no" />}
        onCancel={handleCancelClick}
        title={t('components.userPrompt.addMyPrompt')}
      >
        <Flex gap={12} vertical>
          <div className={styles.contentTip}>{t('components.userPrompt.addPromptTip')}</div>
          <div className={styles.contentCt}>
            <textarea
              ref={contentInputRef}
              className={styles.textarea}
              onChange={handleInput}
              value={content}
              placeholder={t('components.userPrompt.addPromptPlaceHolder')}
            ></textarea>
            <TextSizeTip
              current={content.length}
              max={MAX_USER_PROMPT_CONTENT_COUNT}
              showRatio={0.9}
            />
          </div>
          <Flex
            justify="space-between"
            className={cs(styles.hint, {
              [styles.hintEditing]: showNameInput,
            })}
            gap={10}
          >
            {showNameInput ? (
              <>
                <input
                  value={name}
                  onChange={handleNameChange}
                  className={styles.user_prompt_name_input}
                  ref={nameInputRef}
                  maxLength={MAX_USER_PROMPT_NAME_COUNT}
                />
                <TextSizeTip
                  current={name.length}
                  max={MAX_USER_PROMPT_NAME_COUNT}
                  showRatio={0.9}
                  className={styles.name_size_tip}
                />
                <a className={styles.customize} onClick={handleConfirmClick}>
                  {t('common.confirm')}
                </a>
              </>
            ) : (
              <>
                <Flex gap=".5rem" justify="space-between" className={styles.tip}>
                  <Icon name="popai-a-tishi1" className={styles.notice_icon} />
                  {name ? (
                    <TextOverflow
                      dangerouslySetInnerHTML={{
                        __html: t('components.userPrompt.customizeWithHint', { hint: name }),
                      }}
                    ></TextOverflow>
                  ) : (
                    <span>{t('components.userPrompt.customizeTip')}</span>
                  )}
                </Flex>
                <a className={styles.customize} onClick={handleCustomizeClick}>
                  {t('components.userPrompt.customizeText')}
                </a>
              </>
            )}
          </Flex>
        </Flex>
      </Modal>
    );
  },
);

export default UserPromptFormModal;
