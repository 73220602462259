/*
 * @Author: zuoyuxing001
 * @Date: 2023-12-07 21:11:21
 * @LastEditors: zuoyuxing001
 * @LastEditTime: 2024-01-02 21:52:35
 * @FilePath: /popai/src/components/CreatePresentationButton/index.tsx
 * @Description:
 */
import { createNewChat } from '@/services';
import CustomIcon from '../CustomIcon';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useGASendEvent } from '@/hooks';
import { proChatModelName } from '@/common/model';

import type { ResponseType, Channel } from '@/type';

import styles from './index.module.less';

interface CreatePresentationButtonProps {
  pdfMsgId?: string;
}

const CreatePresentationButton: React.FC<CreatePresentationButtonProps> = ({ pdfMsgId }) => {
  const [processing, setProcessing] = useState(false);
  const { t } = useTranslation();
  const { sendEvent } = useGASendEvent();

  const createPpt = async () => {
    sendEvent('ClickCreatePresentationInDoc');
    try {
      setProcessing(true);
      const res = await createNewChat<ResponseType<Channel>>({
        model: proChatModelName,
        message: '',
        pdfMsgId,
      });
      if (res?.data?.channelId) {
        window.open(`/chat/${res.data.channelId}`);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
    }
  };

  if (!pdfMsgId) {
    return null;
  }
  return (
    <button className="primary" onClick={createPpt} disabled={processing}>
      <CustomIcon type="pptOutlined" className={styles.icon} />
      <span>{t('components.createPresentation.createPresentation')}</span>
    </button>
  );
};

export default CreatePresentationButton;
