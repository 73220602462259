import { useState } from 'react';
import { Channel, PromptTemplate, ResponseType } from '@/type';
import useCreationStore from '@/store/creation';
import useEnhanceUploadStore from '@/store/enhanceUpload';
import { createNewChat } from '@/services/common';
import useChatStore from '@/store/chat';
import { useGlobalModal } from '@/layout/BasicLayout';
import { RESPONSE_DATA_CODE } from '@/common/config';
/**
 * useChannel hook 放置 channel 和 message 相关的逻辑
 * @returns
 */
export const useChannel = () => {
  const [promptTemplate, setPromptTemplate] = useState<PromptTemplate>({});
  const { setPreviewKey } = useCreationStore();
  const { reset } = useEnhanceUploadStore();
  const { setChannel, setSendOptions } = useChatStore();
  const { checkLoginStatus, createComplete } = useGlobalModal();
  const [processing, setProcessing] = useState(false);

  const handleTemplateClear = () => {
    setPromptTemplate({});
    setPreviewKey('');
    reset();
  };

  // 新输入框发送，创建one chat
  const handleNewMessageSend = async (options: any) => {
    try {
      await handleCreateOneChate(options);
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  // 创建one chat
  const handleCreateOneChate = async (options: any) => {
    const { model, template, message, language, pptSettingsParams, fileType, ...restParams } =
      options;
    try {
      const res = await createNewChat<ResponseType<Channel>>({
        model,
        templateId: template?.promptTemplateId ?? '',
        message,
        language,
        fileType,
        ...pptSettingsParams,
        ...restParams,
      });

      setSendOptions({
        ...options,
      });
      if (!options?.channelId || options.channelType !== 'ONE_CHAT') {
        // 有docs的one chat 已经修改过了，所以不用再改
        setChannel(res.data);
      }
      createComplete?.(res?.data?.channelId);
    } catch (err: any) {
      if (err?.response?.data?.code !== RESPONSE_DATA_CODE.UsageExceedsLimit) {
        throw new Error(err?.response?.data?.message || err.message);
      }
    } finally {
      setPromptTemplate({});
      setProcessing(false);
    }
  };

  return {
    promptTemplate,
    handleTemplateClear,
    handleNewMessageSend,
    processing,
    handleCreateOneChate,
    checkLoginStatus,
  };
};
