import { forwardRef, memo, PropsWithChildren, useCallback } from 'react';

import styles from './container.module.less';
import IconButton from '@/components/IconButton';
/**
 * A container contains some suggestions,
 * which displayed above the input box
 */
const SuggestionContainer = memo(
  forwardRef<
    HTMLDivElement,
    PropsWithChildren<{
      title?: string;
      closable?: boolean;
      onClose: () => void;
      active: boolean;
      /**
       * send controller view listens click and will fouce input
       *
       * Stop stopPropagation
       * to keep cursor in the suggestion container,
       * or and any modal popup inside the container
       */
      interpreteClick?: boolean;
    }>
  >(({ title, closable = false, children, onClose, active, interpreteClick = true }, ref) => {
    const clickInterpreter: React.EventHandler<React.MouseEvent> = useCallback(
      (event) => {
        if (interpreteClick) {
          event.stopPropagation();
        }
      },
      [interpreteClick],
    );

    return active ? (
      <div className={styles.container} ref={ref} onClick={clickInterpreter}>
        {title && <header className={styles.header}>{title}</header>}
        <div className={styles.inner}>{children}</div>
        {closable && <IconButton icon="popai-no" onClick={onClose} className={styles.close} />}
      </div>
    ) : null;
  }),
);

export default SuggestionContainer;
