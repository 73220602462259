import { useEffect, useMemo, useRef } from 'react';
import uploadFile from '@/common/uploadFile';
import {
  DOC_FILE_MIME_WHITE_LIST,
  PPT_FILE_MIME_WHITE_LIST,
  STORE_KEY,
  getMultiDocMaxCount,
  isModelPro,
} from '@/common/config';
import { App, Upload } from 'antd';
import { GptUsageItem, useCommonStore, useSendUploadStore, useUserStore } from '@/store';
// import { useGlobalModal } from '@/layout/BasicLayout';
import { useTranslation } from 'react-i18next';
import { useDA, useGASendEvent } from '@/hooks';
import ToastContent from '@/components/ToastContent';
import type { RcFile, UploadProps } from 'antd/es/upload/interface';
import { UPLOAD_STATUS, InputUploadFile } from '@/store/sendUpload';
import { useGlobalModal } from '@/layout/BasicLayout';
import { basicChatModelName } from '@/common/model';

interface UploadFileProps {
  /**当前使用的模型 */
  currentModel: string;
  /**上传类型，enhance: 文档增强，normal: 普通文档 */
  type: 'enhance' | 'normal';
  children?: React.ReactNode;
  /**文件上传前回调 */
  onBeforeUpload?: () => void;
}

export interface UploadFileRef {
  uploadFile: (file: any) => void;
}

const UploadFile: React.FC<UploadFileProps> = (props) => {
  const { type = 'normal', children, currentModel = basicChatModelName } = props;
  const prevExceedConfirmOpen = useRef<boolean | undefined>(undefined);
  const { t } = useTranslation();
  const { sendDAEvent } = useDA();

  const { sendEvent } = useGASendEvent();
  const { checkLoginStatus, checkPayStatus } = useGlobalModal();

  const { message } = App.useApp();

  const { usageInfo } = useCommonStore();
  const { userInfo, guestToken } = useUserStore();
  // const sendUploadStore = useSendUploadStore();
  const {
    allUploadFiles,
    currentUploadFiles,
    setAllUploadFiles,
    setCurrentUploadFiles,
    disable,
    setUploading,
    setFileStatusMap,
    setExceedConfirmOpen,
    exceedConfirmOpen,
    uploadMethod,
    setuploadMethod,
  } = useSendUploadStore();

  const maxCount = useMemo(() => {
    return type === 'normal' ? getMultiDocMaxCount(userInfo, guestToken) : 1;
  }, [guestToken, type, userInfo]);

  const fileWhiteLists = useMemo(() => {
    if (type === 'normal') return DOC_FILE_MIME_WHITE_LIST;
    return [...DOC_FILE_MIME_WHITE_LIST, ...PPT_FILE_MIME_WHITE_LIST];
  }, [type]);

  const openFileDialogOnClick = useMemo(() => {
    return (
      (!!userInfo?.isLogin || !!guestToken) &&
      (userInfo?.isPro || userInfo?.isTrial || !isModelPro(currentModel)) &&
      !usageInfo.docUploadLimitHint
    );
  }, [
    currentModel,
    guestToken,
    usageInfo.docUploadLimitHint,
    userInfo?.isLogin,
    userInfo?.isPro,
    userInfo?.isTrial,
  ]);

  const hasShownErrorMessage = useRef(false);

  const validateFileType = (type: string) => {
    const flag = fileWhiteLists.includes(type);
    if (!flag && !hasShownErrorMessage.current) {
      message.open({
        content: <ToastContent icon="error" content={t('components.upload.uploadDocTypeErr')} />,
      });
      hasShownErrorMessage.current = true;
    }
    return flag;
  };

  const validatePay = () => {
    if (usageInfo.docUploadLimitHint) {
      checkPayStatus?.({
        shortMessage: t('common.filesPerDay', {
          count: (usageInfo?.docUpload as GptUsageItem)?.total || 2,
        }),
        source: 'FREEDAYMAXFILE',
      });
      return false;
    }
    if (!userInfo.isPro && !userInfo.isTrial && isModelPro(currentModel)) {
      checkPayStatus({
        shortMessage: t('common.responseWith', { name: currentModel }),
        source: 'GPT4Turbo',
      });
      return false;
    }
    return true;
  };

  const handleFileSelect = () => {
    const res = checkLoginStatus?.({ type: 'Openfile', model: currentModel });
    // 已登录（真实登录或命中免登录）但无法上传，则需要弹付费弹窗
    if (res && !openFileDialogOnClick) {
      validatePay();
    }
  };

  const uploadProps: UploadProps = {
    name: 'file',
    accept: type === 'normal' ? '.doc,.docx,.pdf' : '.doc,.docx,.ppt,.pptx,.pdf',
    maxCount,
    multiple: type === 'normal',
    disabled: disable,
    data: {
      model: currentModel,
    },
    headers: {
      Authorization: localStorage.getItem(STORE_KEY.Authorization) as string,
    },
    beforeUpload: async (_file: RcFile, fileList: RcFile[]) => {
      console.log('beforeUpload');
      props?.onBeforeUpload?.();
      const filterFiles = fileList.filter((file) => !(file && !validateFileType(file?.type ?? '')));
      let finallValidFiles = [...allUploadFiles, ...filterFiles];
      if (finallValidFiles.length > maxCount) {
        finallValidFiles = finallValidFiles.slice(0, maxCount);
        // model提示超过截取前maxcount个文档上传
        setExceedConfirmOpen(true);
        setCurrentUploadFiles(filterFiles.slice(0, maxCount - allUploadFiles.length));
        setAllUploadFiles(finallValidFiles);
        return false;
      } else {
        setCurrentUploadFiles(filterFiles.slice(0, maxCount - allUploadFiles.length));
        setAllUploadFiles(finallValidFiles);
        return filterFiles.length > 0 || Upload.LIST_IGNORE;
      }
    },
    showUploadList: false,
    openFileDialogOnClick,
    customRequest: async (option: any) => {
      setUploading(true);
      await customFileUpload(option.file);
    },
    onChange: () => {
      sendEvent('Click_UploadFromComputer_Open');
    },
  };

  const updateFiles = (file: InputUploadFile) => {
    setAllUploadFiles((prevFiles) => {
      const index = prevFiles.findIndex((i) => i.uid == file.uid);
      if (index > -1) {
        return prevFiles.map((i, idx) => (idx === index ? file : i));
      }
      return prevFiles;
    });
  };

  const customFileUpload = async (file: any) => {
    setUploading(true);
    file.upload = function () {
      customFileUpload(file);
    };
    const fileUid = file.uid;
    let status = UPLOAD_STATUS.BeforeUpload;

    let uploadTime = Infinity;
    // 上传文件到s3开始时间
    const startUploadS3 = Date.now();
    setFileStatusMap(fileUid, { status, progress: 0 });
    let timer: string | number | NodeJS.Timeout | null | undefined = null;
    let progress = 60;
    try {
      const isFileSizeValid = file.size / 1024 / 1024 <= 200;
      if (!isFileSizeValid) {
        throw new Error(t('components.upload.sizeExceedTip'));
      }
      status = UPLOAD_STATUS.Uploading;
      timer = window.setInterval(() => {
        if (progress < 98) {
          progress = progress + 1;
          setFileStatusMap(fileUid, { status, progress: progress + 1 });
        } else {
          timer && clearInterval(timer);
          timer = null;
          progress = 99;
          setFileStatusMap(fileUid, { status, progress });
        }
      }, 100);
      // 上传
      const res = await uploadFile(file);
      const endUploadS3 = Date.now();
      if (res?.fields.key) {
        file.uploadTime = endUploadS3 - startUploadS3;
        file.md5 = res?.fields.key;
        status = UPLOAD_STATUS.Success;
      }
      uploadTime = endUploadS3 - startUploadS3;
      sendDAEvent('UploadDocumentResult', {
        is_success: true,
        file_type: file.type,
        filename: file.name || file.fileName,
        md5: file.md5,
        duration: uploadTime / 1000,
        failurereason: '',
      });
    } catch (error: any) {
      status = UPLOAD_STATUS.Failed;
      file.error = error?.response?.data?.message || error?.data?.message || error?.message;
      const endUploadS3 = Date.now();
      uploadTime = endUploadS3 - startUploadS3;
      sendDAEvent('UploadDocumentResult', {
        is_success: false,
        file_type: file.type,
        filename: file.name || file.fileName,
        md5: file.md5,
        duration: uploadTime / 1000,
        failurereason: file.error,
      });
    } finally {
      setFileStatusMap(fileUid, { status, progress });
      updateFiles(file);
      timer && clearInterval(timer);
      timer = null;
    }
  };

  useEffect(() => {
    // 仅在exceedConfirmOpen变化时更新ref;
    if (prevExceedConfirmOpen.current !== exceedConfirmOpen) {
      if (
        !exceedConfirmOpen &&
        prevExceedConfirmOpen.current === true &&
        uploadMethod === 'computer'
      ) {
        // 确保currentUploadFiles不为空
        if (currentUploadFiles.length > 0) {
          currentUploadFiles.forEach((file) => {
            customFileUpload(file);
          });
        }
      }
      prevExceedConfirmOpen.current = exceedConfirmOpen;
    }
  }, [exceedConfirmOpen, currentUploadFiles, uploadMethod]);

  return (
    <div onClick={() => setuploadMethod('computer')}>
      <Upload {...uploadProps}>
        <div onClick={handleFileSelect}>{children}</div>
      </Upload>
    </div>
  );
};

export default UploadFile;
