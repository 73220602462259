import { HTMLAttributes, startTransition, useCallback, useEffect, useRef, useState } from 'react';
import { Loading } from '../Loading';
import styles from './index.module.less';

function AutoLoadMore({
  load: loadFn,
  children,
  ...props
}: React.PropsWithChildren<{
  /**
   * load funciton, resolve with false to stop load more
   */
  load: (...args: unknown[]) => Promise<boolean>;
}> &
  HTMLAttributes<HTMLDivElement>) {
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const autoloadRef = useRef<HTMLDivElement>(null);

  const load = useCallback(() => {
    setLoading((loading) => {
      if (loading) return loading;

      loadFn().then(
        (hasMore) => {
          setHasMore(hasMore);
          startTransition(() => {
            setLoading(false);
          });
        },
        () => {
          startTransition(() => {
            setLoading(false);
          });
        },
      );
      return true;
    });
  }, [loadFn]);

  useEffect(() => {
    const ob = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          load();
        }
      });
    });

    if (autoloadRef.current) {
      ob.observe(autoloadRef.current);
    }

    return () => {
      ob.disconnect();
    };
  });

  return (
    <div {...props}>
      {children}
      {loading && (
        <div className={styles['loading-ct']}>
          <Loading></Loading>
        </div>
      )}
      <div ref={autoloadRef} style={{ display: hasMore && !loading ? 'block' : 'none' }}></div>
    </div>
  );
}

export default AutoLoadMore;
