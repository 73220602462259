import { HTMLAttributes, memo } from 'react';
import styles from './index.module.less';
import cs from 'classnames';

const TextSizeTip = memo(function TextSizeTip({
  max,
  current,
  showRatio,
  className,
}: {
  max: number;
  current: number;
  showRatio: number;
} & HTMLAttributes<HTMLDivElement>) {
  return current / max >= showRatio ? (
    <div className={cs(current > max ? styles.size_tip_error : styles.size_tip, className)}>
      <span>{current}</span>
      <span>/{max}</span>
    </div>
  ) : null;
});

export default TextSizeTip;
