import { Button, message, Popover, Space } from 'antd';
import cs from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import HighLight from '@/components/HighLight';
import IconButton from '@/components/IconButton';
import { Loading } from '@/components/Loading/';
import { useUserPromptStore } from '@/store';
import { UserPrompt } from '@/type';
import { UserPromptFormModal } from '@/components/UserPrompt';
import styles from './promptItem.module.less';
import { useDA } from '@/hooks';
import ToastContent from '@/components/ToastContent';
import TextOverflow from '@/components/TextOverflow';

// https://stackoverflow.com/questions/18862256/how-to-detect-emoji-using-javascript
const emojiRegExp = /^\p{Extended_Pictographic}/u;
const emojiRegExp_Replace = /^(\p{Extended_Pictographic})(.*)$/u;
/**
 * split user prompt name with emoji and content
 */
function splitName(name: string): [emoji: string, nameText: string] {
  let emoji = '',
    nameText = name.trim();
  if (emojiRegExp.test(name)) {
    name = name.replace(emojiRegExp_Replace, function (_, $1, $2) {
      emoji = $1;
      nameText = $2.trim();
      return name;
    });
  }
  return [emoji, nameText];
}

const PromptItem = function PromptItem({
  promptId,
  content,
  name,
  saved = true,
  onClick,
}: UserPrompt & { onClick: (value: string) => void; highlight?: string; saving?: boolean }) {
  const { t } = useTranslation();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const delPrompt = useUserPromptStore((state) => state.delete);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(!saved);
  const { sendDAEvent } = useDA();

  const handleConfirmDeleteClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setDeleting(true);
      if (!deleting) {
        delPrompt(promptId).then(() => {
          setDeleting(false);
          setDeleteModalOpen(false);
          message.open({
            content: (
              <ToastContent icon="success" content={t('components.userPrompt.deleteSuccess')} />
            ),
          });
        });
      }
    },
    [delPrompt, deleting, promptId, t],
  );

  const onEditClick = useCallback(
    (e: React.MouseEvent) => {
      sendDAEvent('EditMyPrompt', {
        button_name: 'EditMyPrompt',
      });
      e.stopPropagation();
      setEditing(true);
    },
    [sendDAEvent],
  );

  const onItemClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onClick(content);
    },
    [content, onClick],
  );

  const handleCancelClick = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setDeleteModalOpen(false);
  }, []);

  const handleDeleteClick = useCallback((event: React.MouseEvent<Element, MouseEvent>): void => {
    event.stopPropagation();
    setDeleteModalOpen(true);
  }, []);

  const handlePopContentMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      event.stopPropagation();
      event.preventDefault();
    },
    [],
  );

  const onEditCancel = useCallback((): void => {
    setEditing(false);
  }, []);

  const onSubmit = useCallback((promise: Promise<unknown>): void => {
    setEditing(false);
    setSaving(true);
    promise.then(() => setSaving(false));
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);
  const delButtonRef = useRef<HTMLButtonElement>(null);

  const [emoji, nameText] = splitName(name);

  const { originName } = useUserPromptStore((state) => ({
    originName: state.items.find((item) => item.promptId === promptId)?.name || name,
  }));
  return (
    <div ref={containerRef}>
      <div className={deleteModalOpen ? styles['prompt_item--pop_active'] : styles.prompt_item}>
        <div className={styles.main} onClick={onItemClick}>
          <TextOverflow className={styles.name}>
            {saving ? (
              <>
                <span className={styles.name_icon}>
                  <Loading />
                </span>
                {name || t('components.userPrompt.generatingHint')}
              </>
            ) : (
              <>
                {emoji ? <span className={styles.emoji}>{emoji}</span> : null}
                {nameText ? <HighLight content={nameText} tag="em" /> : nameText}
              </>
            )}
          </TextOverflow>
          <TextOverflow className={styles.content}>{content}</TextOverflow>
        </div>
        <div className={styles.actions}>
          <IconButton
            icon="popai-rename"
            className={cs(styles.edit_action)}
            onClick={onEditClick}
            size="medium"
          ></IconButton>
          <Popover
            overlayClassName={styles.delete_pop}
            trigger="click"
            placement="top"
            color="#fff"
            getPopupContainer={() => containerRef.current as HTMLElement}
            content={
              <div onMouseDown={handlePopContentMouseDown}>
                <div className={styles.delete_pop_title}>
                  {t('components.userPrompt.deleteTitle')}
                </div>
                <Space className="buttons" size={8}>
                  <Button onClick={handleCancelClick} className={styles.pop_cancel_button}>
                    {t('common.cancel')}
                  </Button>
                  <Button
                    danger
                    type="primary"
                    onClick={handleConfirmDeleteClick}
                    className={styles.pop_delete_button}
                  >
                    {deleting ? <Loading className={styles.loading} /> : t('common.delete')}
                  </Button>
                </Space>
              </div>
            }
            open={deleteModalOpen}
            onOpenChange={setDeleteModalOpen}
          >
            <IconButton
              icon="popai-deleta"
              type="danger"
              className={cs(styles.delete_action)}
              onClick={handleDeleteClick}
              ref={delButtonRef}
              size="medium"
            ></IconButton>
          </Popover>
        </div>
      </div>
      {editing && (
        <UserPromptFormModal
          getContainer={() => containerRef.current as HTMLElement}
          onSubmit={onSubmit}
          onCancel={onEditCancel}
          promptId={promptId}
          name={originName}
          content={content}
          className={styles.edit_modal}
        />
      )}
    </div>
  );
};

export default PromptItem;
