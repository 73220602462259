import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { FEATURE_TIPS_INFO, STORE_KEY } from '@/common/config';
import { useGASendEvent, useSundry } from '@/hooks';
import { useGlobalModal } from '@/layout/BasicLayout';
import PromptNotesModal from '@/pages/Home/components/PromptNotesModal';
import { getPromptNotes } from '@/services';
import { useCommonStore, useUserStore } from '@/store';
import type { ResponseType } from '@/type';

const ReleaseNote = () => {
  const { userInfo } = useUserStore();
  const pageLanguage = useCommonStore((state) => state.pageLanguage);
  const { currentTipGroup } = useSundry();
  const { checkPayStatus } = useGlobalModal();
  const { sendEvent } = useGASendEvent();

  const [promptNotesModalOpen, setPromptNotesModalOpen] = useState(false);
  const [promptNotes, setPromptNotes] = useState({});

  const handlePromptNotesClose = useCallback(() => {
    setPromptNotesModalOpen(false);
  }, [setPromptNotesModalOpen]);

  const onUpgrade = () => {
    setPromptNotesModalOpen(false);
    checkPayStatus?.();
    sendEvent('Clickpay');
  };

  // 获取当前最新的更新提示信息
  const fetchPromptNotes = useCallback(async () => {
    try {
      const nowdate = dayjs().format('YYYY-MM-DD');
      const createDate = dayjs(userInfo.createdAt).format('YYYY-MM-DD');
      const res = await getPromptNotes<
        ResponseType<{
          id: number;
          version?: string;
          createdAt?: string;
          notes?: Array<string>;
          pictures: Array<string>;
        }>
      >();
      const userPromptId = localStorage.getItem(STORE_KEY.UpdatePrompt + userInfo?.uid);
      let promptNotesNum = localStorage.getItem(STORE_KEY.PromptNotesNum + userInfo?.uid);
      // 注册之后的releaseNote才弹
      const isReleaseNoteOlderThanUser = dayjs(userInfo.createdAt).isBefore(res.data.createdAt);
      if (
        nowdate > createDate &&
        isReleaseNoteOlderThanUser &&
        (!userPromptId || Number(userPromptId) < res.data.id)
      ) {
        localStorage.setItem(STORE_KEY.UpdatePrompt + userInfo?.uid, res.data.id + '');
        setPromptNotesModalOpen(true);
        setPromptNotes({
          version: res.data.version,
          notes: res.data.notes,
          pictures: res.data.pictures,
        });
        promptNotesNum = promptNotesNum ? Number(promptNotesNum) + 1 + '' : '1';
        // 记录弹窗次数
        localStorage.setItem(STORE_KEY.PromptNotesNum + userInfo?.uid, promptNotesNum);
        sendEvent('ReleasePop-up', {
          nums: promptNotesNum,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [
    handlePromptNotesClose,
    onUpgrade,
    userInfo?.isLogin,
    promptNotesModalOpen,
    sendEvent,
    userInfo,
    currentTipGroup,
  ]);

  useEffect(() => {
    if (
      userInfo?.isLogin &&
      (!!FEATURE_TIPS_INFO?.[0]?.length
        ? currentTipGroup?.[0]?.tag !== undefined && currentTipGroup?.[0]?.tag === ''
        : true)
    ) {
      // 获取是否展示更新提示
      fetchPromptNotes();
    }
  }, [userInfo?.isLogin, currentTipGroup]);

  return (
    <>
      {userInfo?.isLogin && pageLanguage !== 'ar' && (
        <PromptNotesModal
          open={promptNotesModalOpen}
          promptNotes={promptNotes}
          onClose={handlePromptNotesClose}
          onUpgrade={onUpgrade}
        />
      )}
    </>
  );
};

export default ReleaseNote;
