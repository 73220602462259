import Icon from '@/components/Icon';
import { FileType } from '@/common/helpers/fileHelper';
import styles from './index.module.less';
import { ComponentProps, memo } from 'react';
import cs from 'classnames';
import writingIcon from '@/assets/workspace/file-writing.svg';
import presentationIcon from '@/assets/workspace/file-presention.svg';

/**
 * Unify file icons here
 */
function FileIcon({
  type,
  className,
  ...others
}: {
  /**
   * developers should use helpers/fileHelper to getFileType
   */
  type: FileType;
} & Omit<ComponentProps<typeof Icon>, 'name'>) {
  let name;

  switch (type) {
    case FileType.image:
      name = 'popai-a-image1';
      break;
    case FileType.ppt:
      name = 'popai-a-pdfcolor-test';
      break;
    case FileType.pdf:
      name = 'popai-a-pdfcolor-test1';
      break;
    case FileType.doc:
      name = 'popai-a-doccoloe1';
      break;
    case FileType.presentation:
      // TODO fix with icon font
      return <img src={presentationIcon} className={className} {...others} />;
      break;
    default:
    case FileType.writing:
      // TODO fix with icon font
      return <img src={writingIcon} className={className} {...others} />;
    case FileType.unknown:
      name = 'file';
  }
  return <Icon name={name} className={cs(styles[`icon-file-${type}`], className)} {...others} />;
}

export default memo(FileIcon);
