import Slogan from '@/components/SloganV2';

import './index.less';

interface Props {
  type: 'home' | 'creation' | 'upload' | 'writing' | 'workspace' | 'more';
  containerStyle?: React.CSSProperties;
}

const PageSlogan = (props: Props) => {
  const { type, containerStyle = {} } = props;
  return (
    <h1 className="page-slogan" style={containerStyle}>
      <Slogan page={type} />
    </h1>
  );
};

export default PageSlogan;
