import { useRef, useState, useEffect } from 'react';
import { useEnhanceUploadStore, useSendUploadStore } from '@/store';
import classNames from 'classnames';
import { InputUploadFile, UPLOAD_STATUS } from '@/store/sendUpload';
import { throttle } from 'lodash-es';
import FileUploadStatus from '@/components/FileUploadStatus';
import File from '@/components/File';
import { FileType, getFileType } from '@/common/helpers/fileHelper';

const FileList = () => {
  const inputFilesRef = useRef<HTMLDivElement>(null);
  const [filesMarginTop, setFilesMarginTop] = useState(false);

  const file = useEnhanceUploadStore((state) => state.file);
  const allUploadFiles = useSendUploadStore((state) => state.allUploadFiles);
  const fileStatusMap = useSendUploadStore((state) => state.fileStatusMap);
  const onRemoveFile = useSendUploadStore((state) => state.onRemoveFile);

  useEffect(() => {
    if (allUploadFiles.length > 0) {
      setTimeout(() => {
        const element = inputFilesRef.current;
        if (element) {
          element.scrollTop = element.scrollHeight + 20;
        }
      }, 100);
    }
  }, [allUploadFiles.length]);

  const handleFilesScroll = () => {
    const scrollTop = inputFilesRef.current?.scrollTop || 0;
    setFilesMarginTop(scrollTop > 0);
  };

  if (allUploadFiles.length) {
    return (
      <div
        className={classNames({
          'input-files': true,
          marginTop: filesMarginTop,
        })}
        ref={inputFilesRef}
        onScroll={throttle(handleFilesScroll, 100)}
      >
        {allUploadFiles.map((file: InputUploadFile) => {
          const fileStatus = fileStatusMap?.[file.uid];
          return (
            <File
              key={file.uid}
              type={getFileType(file?.type, FileType.doc)}
              title={file.name}
              error={fileStatus?.status === UPLOAD_STATUS.Failed ? file?.error : undefined}
              showProcess={fileStatus?.status === UPLOAD_STATUS.Uploading}
              desc={
                fileStatus?.status === UPLOAD_STATUS.Uploading ? `${fileStatus?.progress}%` : ''
              }
              progress={fileStatus?.progress}
              onRemove={() => {
                onRemoveFile(file.uid);
              }}
              onRetry={() => {
                file.upload?.();
              }}
            />
          );
        })}
      </div>
    );
  }
  if (file) {
    return (
      <div className="input-files">
        <FileUploadStatus />
      </div>
    );
  }
  return null;
};

export default FileList;
