import { lazy } from 'react';
import withSuspense from '@/hoc/LazySuspense';

export const demos = () => {
  if (!import.meta.env.DEV) return [];

  const Presentation = withSuspense(lazy(() => import('@/demos/Presentation')));
  const SendControllerDemo = withSuspense(lazy(() => import('@/demos/SendControllerDemo')));
  const MessagesDemo = withSuspense(lazy(() => import('@/demos/MessagesDemo')));
  const BaseComponentsDemo = withSuspense(lazy(() => import('@/demos/BaseComponentsDemo')));

  return [
    {
      path: 'demo/presentation',
      element: <Presentation />,
    },
    {
      path: 'demo/sendcontroller',
      element: <SendControllerDemo />,
    },
    {
      path: 'demo/messages',
      element: <MessagesDemo />,
    },
    {
      path: 'demo/basecomponents',
      element: <BaseComponentsDemo />,
    },
  ];
};
