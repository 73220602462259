import { useState, useMemo, useCallback } from 'react';
import { TipsData, PromptTemplate } from '@/type';
import { SendControllerViewProps } from '../types';
import { useChatStore, useSendStore, useSendUploadStore } from '@/store';

import { throttle } from 'lodash-es';
import { useDA, useGASendEvent } from '@/hooks';

const useSendController = (
  props: Pick<
    SendControllerViewProps,
    | 'channelId'
    | 'questionAddons'
    | 'onStop'
    | 'lastMessage'
    | 'selectedImageProgress'
    | 'onClearSelectedImage'
    | 'onSuggestionActiveChange'
  > & {
    switchChecked?: boolean;
    realTemplate?: PromptTemplate;
    tplList?: PromptTemplate[];
  },
) => {
  const {
    questionAddons,
    onStop,
    lastMessage,
    selectedImageProgress = false,
    onClearSelectedImage,
    onSuggestionActiveChange,
  } = props;

  const [suggestionActive, setSuggestionActive] = useState(false);

  // store

  // TODO：这里要优化一下，输入框的数据尽量与channel的数据解藕 @zhangfang
  const { showOneChatGUI } = useChatStore((state) => ({
    showOneChatGUI: state.showOneChatGUI,
  }));

  const { allUploadFiles, setAllUploadFiles, onReset, setImageUrls } = useSendUploadStore(
    (state) => ({
      allUploadFiles: state.allUploadFiles,
      setAllUploadFiles: state.setAllUploadFiles,
      onReset: state.onReset,
      setImageUrls: state.setImageUrls,
    }),
  );
  const { setValue, setInnerTpl } = useSendStore((state) => ({
    setValue: state.setValue,
    setInnerTpl: state.setInnerTpl,
  }));

  // hooks
  const { sendEvent } = useGASendEvent();
  const { sendDAEvent } = useDA();

  const showQuestionAddons = useMemo(() => {
    const _showQuestionAddons =
      !props?.realTemplate?.id &&
      (questionAddons?.length ?? 0) > 0 &&
      !questionAddons?.every((item) => !item);

    if (_showQuestionAddons) {
      setImageUrls([]);
      setAllUploadFiles([]);
    }

    return _showQuestionAddons;
  }, [props?.realTemplate?.id, questionAddons, setImageUrls, setAllUploadFiles]);

  const showPPTModelSelect = useMemo(() => {
    return !!props?.realTemplate?.id && props?.realTemplate?.mediaType === 'ppt';
  }, [props?.realTemplate?.id, props?.realTemplate?.mediaType]);

  const showFileTip = useMemo(() => {
    return showOneChatGUI && props?.channelId && !suggestionActive;
  }, [showOneChatGUI, props?.channelId, suggestionActive]);

  const createPPTShow = useMemo(() => {
    return lastMessage && lastMessage.actions?.some((action) => action.name === 'pdfOutLine');
  }, [lastMessage]);

  const pdfMsgId = useMemo(() => {
    return lastMessage?.messageId;
  }, [lastMessage?.messageId]);

  const handleChooseQuestion = useCallback(
    (item: TipsData, type: string) => {
      if (allUploadFiles.length > 0 && type === 'presentation') return false;
      if (selectedImageProgress) return false;
      if (type === 'presentation') {
        setInnerTpl({
          ...props?.tplList?.[0],
          id: item.templateId,
        });
        // 清空文件
        onReset();
        // 清除截图
        onClearSelectedImage?.();
      } else {
        setInnerTpl({});
      }
      setValue(item.value);
    },
    [
      allUploadFiles.length,
      onClearSelectedImage,
      onReset,
      props?.tplList,
      selectedImageProgress,
      setInnerTpl,
      setValue,
    ],
  );

  const handleStopGenerate = useCallback(() => {
    onStop?.();
    sendEvent('Stopgenerating');
    sendDAEvent('StopGenerationButtonClick');
  }, [onStop, sendDAEvent, sendEvent]);

  const handleSuggestionActive = useCallback(
    (active: boolean) => {
      onSuggestionActiveChange?.(active);
      setSuggestionActive(active);
    },
    [onSuggestionActiveChange],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleStopGenerateThrottle = useCallback(throttle(handleStopGenerate, 600), [
    handleStopGenerate,
  ]);

  return {
    showQuestionAddons,
    showPPTModelSelect,
    showFileTip,
    createPPTShow,
    pdfMsgId,
    handleChooseQuestion,
    handleSuggestionActive,
    handleStopGenerateThrottle,
  };
};

export default useSendController;
