import { forwardRef, HTMLAttributes } from 'react';
import cs from 'classnames';
import Icon from '@/components/Icon';

import styles from './icon.module.less';

interface IconButtonProps {
  icon: string;
  type?: 'primary' | 'default' | 'danger';
  /**
   *
   * small: 24px, icon: 14px;
   *
   * medium: 28px; icon: 20px;
   */
  size?: 'small' | 'medium';
}

const IconButton = forwardRef<
  HTMLButtonElement,
  IconButtonProps & HTMLAttributes<HTMLButtonElement>
>(({ icon, type = 'default', className, size = 'small', ...others }, ref) => {
  return (
    <button className={cs(styles[type], className)} {...others} ref={ref} data-size={size}>
      <Icon name={icon} size={size} />
    </button>
  );
});

export default IconButton;
