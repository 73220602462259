// 引用文件
import CustomIcon from '@/components/CustomIcon';
import { EFileType } from '@/type';

export interface QuoteFilesProps {
  /**引用文件类型 */
  type?: EFileType;
  /**引用文件名称 */
  title?: string | React.ReactNode;
  /**取消引用文件的回调 */
  onCancelQuote?: () => void;
}

const QuoteFiles: React.FC<QuoteFilesProps> = (props) => {
  const { type = EFileType.DOC, title = '' } = props;

  if (type === EFileType.WRITING) {
    return (
      <div className="quotefiles">
        <CustomIcon type="fileQuote" />
        <div className="quotefiles-title">
          <CustomIcon type="writingFileBig" />
          <section>{title}</section>
        </div>

        {props?.onCancelQuote && (
          <CustomIcon type="close" className="cancel" onClick={props?.onCancelQuote} />
        )}
      </div>
    );
  }
  return null;
};

export default QuoteFiles;
