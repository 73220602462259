import ConfirmModal from '@/layout/BasicLayout/components/ConfirmModal';
import { useSendUploadStore } from '@/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';

interface ExceedFileModalProps {
  maxCount: number;
}

const ExceedFileModal: React.FC<ExceedFileModalProps> = (props) => {
  const { t } = useTranslation();
  const { maxCount = 2 } = props;

  const {
    exceedConfirmOpen,
    currentUploadFiles,
    allUploadFiles,
    setExceedConfirmOpen,
    setUploading,
    setAllUploadFiles,
    setCurrentUploadFiles,
    onRest,
  } = useSendUploadStore(
    (state) => ({
      exceedConfirmOpen: state.exceedConfirmOpen,
      currentUploadFiles: state.currentUploadFiles,
      allUploadFiles: state.allUploadFiles,
      setExceedConfirmOpen: state.setExceedConfirmOpen,
      setUploading: state.setUploading,
      setAllUploadFiles: state.setAllUploadFiles,
      setCurrentUploadFiles: state.setCurrentUploadFiles,
      onRest: state.onReset,
    }),
    shallow,
  );

  const handleExceedUploadConfirm = useCallback(() => {
    setExceedConfirmOpen(false);
    setAllUploadFiles((prev) => {
      const remainFiles = prev.filter((_, index) => index < maxCount);
      return remainFiles;
    });
  }, [setExceedConfirmOpen, setUploading]);

  const handleExceedUploadCancel = useCallback(() => {
    const remainNum = allUploadFiles?.length - currentUploadFiles.length;
    if (remainNum === 0) {
      onRest();
    }
    setAllUploadFiles(allUploadFiles.slice(0, remainNum));
    setCurrentUploadFiles([]);
    setExceedConfirmOpen(false);
  }, [
    allUploadFiles.length,
    currentUploadFiles.length,
    setAllUploadFiles,
    setCurrentUploadFiles,
    setExceedConfirmOpen,
  ]);

  return (
    <ConfirmModal
      open={exceedConfirmOpen}
      onCancel={handleExceedUploadCancel}
      onConfirm={handleExceedUploadConfirm}
      title={t('components.upload.exceedTitle', {
        maxCount,
      })}
      desc={t('components.upload.exceedDesc', {
        maxCount,
      })}
      okText={t('components.upload.exceedOkText', {
        maxCount,
      })}
      cancelText={t('components.upload.exceedCancelText')}
    />
  );
};

export default ExceedFileModal;
