/*
 * @Author: wubo
 * @Date: 2023-07-03 20:01:11
 * @LastEditTime: 2023-12-19 13:36:18
 * @LastEditors: zuoyuxing001
 * @Description:
 */
import { useUserStore } from '@/store';
import { Modal, Carousel } from 'antd';
// import NotesDefault from '@/assets/prompt-notes-default.png';
import NotesDefault from '@/assets/prompt-notes-default-ar.png';
import { useGASendEvent } from '@/hooks';
import CustomIcon from '@/components/CustomIcon';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import './index.less';

interface PromptNotesProps {
  open: boolean;
  promptNotes?: {
    notes?: Array<string>;
    pictures?: Array<string>;
    version?: string;
  };
  onClose?: (isRefresh?: boolean) => void;
  onUpgrade?: () => void;
}

const PromptNotesModal: React.FC<PromptNotesProps> = (props) => {
  const { t } = useTranslation();
  const { open, onClose, promptNotes, onUpgrade } = props;
  const { userInfo } = useUserStore();
  const navigate = useNavigate();
  const { sendEvent } = useGASendEvent();
  const handleCancel = () => {
    sendEvent('CloseRelease');
    onClose?.();
  };

  const handleUpgradePro = () => {
    sendEvent('SubscribeRelease');
    onUpgrade?.();
  };

  const releaseNotes = () => {
    sendEvent('AllRelease', {
      releaseSource: 'Popup',
    });
    navigate('/releasenote');
    onClose?.();
  };

  const handleGetStarted = () => {
    sendEvent('GetstartRelease');
    onClose?.();
  };
  return (
    <Modal
      centered
      wrapClassName="prompt-notes-modal"
      open={open}
      footer={null}
      onCancel={handleCancel}
      maskClosable={false}
      width={690}
    >
      <div className="prompt-notes-container">
        {/* 左侧图片滚动区 */}
        <div className="notes-left-container">
          <Carousel
            className="notes-carousel"
            autoplay={promptNotes?.pictures && promptNotes?.pictures.length > 1 ? true : false}
          >
            {promptNotes?.pictures ? (
              promptNotes?.pictures?.map((item, index) => {
                return (
                  <div key={'pic-' + index}>
                    <img className="notes-carousel-img" src={item}></img>
                  </div>
                );
              })
            ) : (
              <div>
                <img className="notes-carousel-img" src={NotesDefault}></img>
              </div>
            )}
          </Carousel>
        </div>
        {/* 右侧文案展示区 */}
        <div className="notes-right-container">
          <div className="top-box">
            <span className="title">
              <CustomIcon className="title-icon" type="notesLogoText" />
              PopAi
            </span>
            <span className="sub-title">{t('pages.home.newVersionModal')}</span>
          </div>
          <div className={`notes-content-box ${userInfo.isPro ? 'no-pro-btn' : ''}`}>
            <ul>
              {promptNotes?.notes ? (
                promptNotes?.notes?.map((item) => (
                  <li dangerouslySetInnerHTML={{ __html: item }}></li>
                ))
              ) : (
                <li>{t('pages.home.newVersionMoadlContent')}</li>
              )}
            </ul>
            <a className="release-notes-link" onClick={releaseNotes}>
              {t('pages.home.All_release')} &gt;&gt;
            </a>
          </div>
          <div className="btn-box">
            {!userInfo.isPro && (
              <button className="upgrade-pro-btn" onClick={handleUpgradePro}>
                <CustomIcon className="upgrade-icon" type="upgradePro" />
                {t('pages.home.Upgrade_to_Pro')}
              </button>
            )}
            <button className="get-start-btn" onClick={handleGetStarted}>
              <CustomIcon type="noteLogo" /> {t('pages.home.Get_Started')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PromptNotesModal;
