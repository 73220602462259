import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input, InputRef, Popover } from 'antd';
import CustomIcon from '../CustomIcon';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { isUrlEndWithPdf } from '@/common/utils';
import { useDA, useGASendEvent } from '@/hooks';
import { useGlobalModal } from '@/layout/BasicLayout';

import './index.less';
import { TooltipPlacement } from 'antd/es/tooltip';
import AddFileItemUi from '../AddFileItemUi';
import { Channel } from '@/type';
import { getChatType } from '@/common/helpers/chatHelper';
import { useCommonStore, useUserStore } from '@/store';
import { isModelPro } from '@/common/config';
import { basicChatModelName } from '@/common/model';

interface PDFUrlInputSimpleProps {
  placement?: TooltipPlacement;
  arrow?: boolean;
  fileUrl: string;
  showIcon?: boolean;
  showPlugin?: boolean;
  onFileUrlChange: (value: string) => void;
  onUploadURL?: (params: { url: string }) => void;
  channel?: Channel;
  children?: React.ReactNode;
  currentModel?: string;
}

const PDFUrlInputSimple: React.FC<PDFUrlInputSimpleProps> = (props) => {
  const {
    fileUrl,
    showIcon = true,
    onFileUrlChange,
    onUploadURL,
    placement = 'bottom',
    arrow = true,
    channel,
    children,
    currentModel = basicChatModelName,
  } = props;

  const { sendEvent, sendEventDelay } = useGASendEvent();
  const { sendDAEvent, preparePropertyFor } = useDA();
  const { checkLoginStatus, checkPayStatus } = useGlobalModal();
  const { userInfo } = useUserStore();
  const { usageInfo } = useCommonStore();

  const { t } = useTranslation();

  const [urlPopoverOpen, setUrlPopoverOpen] = useState(false);

  const urlInputRef = useRef<InputRef>(null);

  const uploadUrlDisabled = useMemo(() => {
    return !isUrlEndWithPdf(fileUrl);
  }, [fileUrl]);

  const validatePay = useCallback(() => {
    if (usageInfo.docUploadLimitHint) {
      checkPayStatus?.({
        shortMessage: t('common.filesPerDay', { count: 2 }),
        source: 'FREEDAYMAXFILE',
      });
      return false;
    }
    if (!userInfo.isPro && !userInfo.isTrial && isModelPro(currentModel)) {
      checkPayStatus({
        shortMessage: t('common.responseWith', { name: currentModel }),
        source: 'GPT4Turbo',
      });
      return false;
    }
    return true;
  }, [
    currentModel,
    checkPayStatus,
    t,
    usageInfo.docUploadLimitHint,
    userInfo.isPro,
    userInfo.isTrial,
  ]);

  const handleUrlPopoverOpenChange = useCallback(
    (open: boolean) => {
      if (!checkLoginStatus?.({ type: 'openurl' })) return;
      if (validatePay()) {
        setUrlPopoverOpen(open);
      }
    },
    [checkLoginStatus, validatePay],
  );

  const handleUrlInputUpload = async () => {
    sendEvent('Click_FromURL_Upload');
    if (fileUrl) {
      sendEvent('Urlupload');
      setUrlPopoverOpen(false);
      onUploadURL?.({
        url: fileUrl,
      });
      sendEvent('Uploadedurl');
      sendDAEvent('UploadDocumentResult', { is_success: true, upload_method: 'one_chat_from_url' });
    }
  };

  const handleOnlineFileUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value?.trim();

    onFileUrlChange(url);
    sendEventDelay('Inputurl', {
      inputContent: url,
    });
  };

  const handleUrlInputPressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event?.stopPropagation();
    isUrlEndWithPdf(fileUrl) && handleUrlInputUpload();
  };

  const urlLinkInputRender = () => (
    <div className="upload-url-container" onClick={(event) => event?.stopPropagation()}>
      <Input
        className="upload-url-input"
        placeholder={t('pages.home.Paste_URL')}
        prefix={<CustomIcon className="link-icon" type="link" />}
        suffix={
          <CustomIcon
            className={classNames({
              'upload-file-icon': true,
              'upload-file-icon-disabled': uploadUrlDisabled,
            })}
            type="upload"
            onClick={handleUrlInputUpload}
          />
        }
        // disabled={uploadStore.uploading}
        value={fileUrl}
        ref={urlInputRef}
        onChange={handleOnlineFileUrlChange}
        onPressEnter={handleUrlInputPressEnter}
      />
      <div className="url-input-hint">
        {t('pages.home.Supported_sources')}:{' '}
        <a href="https://arxiv.org/" target="_blank">
          https://arxiv.org/
        </a>{' '}
        {t('pages.upload.pdfUrl')}
      </div>
    </div>
  );

  useEffect(() => {
    if (urlPopoverOpen) {
      urlInputRef?.current?.focus();
    }
  }, [urlPopoverOpen]);

  return (
    <Popover
      overlayClassName="upload-url-input-popover simple-mode"
      trigger="click"
      arrow={arrow}
      placement={placement}
      autoAdjustOverflow
      destroyTooltipOnHide
      open={urlPopoverOpen}
      onOpenChange={handleUrlPopoverOpenChange}
      content={urlLinkInputRender()}
    >
      <AddFileItemUi
        onClick={(event) => {
          event?.stopPropagation();
          sendEvent('Click_FromURL');
          sendDAEvent('UploadFiles_Click', {
            upload_method: 'url',
            channelid: channel?.channelId || '',
            chattype: (channel && getChatType(channel)) || '',
          });
          sendDAEvent('DocumentUploadButtonClick', { upload_method: 'one_chat_from_url' });
          preparePropertyFor('UploadDocumentResult', 'upload_method', 'one_chat_from_url');
        }}
        iconType={showIcon ? 'link' : null}
        text={t('pages.home.fromURL')}
      >
        {children}
      </AddFileItemUi>
    </Popover>
  );
};

export default PDFUrlInputSimple;
