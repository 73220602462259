import { gpt4oName } from '@/common/model';
import CustomIcon from '@/components/CustomIcon';
import { useGlobalModal } from '@/layout/BasicLayout';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';

interface CaptureImageProps {
  showCaptureImg?: string | false;
  disabled?: boolean;
  tooltip?: string;
  canCapture?: boolean;
  onScreenshot?: () => void;
}

const CaptureImage: React.FC<CaptureImageProps> = (props) => {
  const {
    showCaptureImg = false,
    disabled = false,
    tooltip = '',
    canCapture = false,
    onScreenshot,
  } = props;
  const cls = useMemo(() => {
    return classNames({
      operator: true,
      disabled,
    });
  }, [disabled]);

  const { checkLoginStatus } = useGlobalModal();

  const handleCapture = useCallback(() => {
    if (!checkLoginStatus({ type: 'Capture', model: gpt4oName })) {
      return;
    }
    canCapture && onScreenshot?.();
  }, [canCapture, checkLoginStatus, onScreenshot]);

  if (!showCaptureImg) return null;
  return (
    <CustomIcon className={cls} type="popai-screenshot" tooltip={tooltip} onClick={handleCapture} />
  );
};

export default CaptureImage;
