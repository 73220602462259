import { useEnhanceUploadStore } from '@/store';
import { useGASendEvent } from '@/hooks';
import './index.less';
import File from '@/components/Messages/File';
import { UPLOAD_STATUS } from '@/store/sendUpload';
// interface FileUploadStatusProps {
//   /**文件类型doc,pdf,ppt */
//   fileType?: string
// }

const FileUploadStatus: React.FC = () => {
  const {
    uploadStatus,
    uploadProgress,
    uploadError,
    file,
    reset,
    upload,
    fileExtraInfo,
    parsingFile,
    fileType,
  } = useEnhanceUploadStore();
  const { sendEvent } = useGASendEvent();

  /**根据文件上传状态展示状态文案 */
  const statusText = () => {
    if (uploadStatus === UPLOAD_STATUS.Failed) return undefined;
    if (uploadStatus === UPLOAD_STATUS.Parsing) return 'Parsing...';
    if (uploadStatus === UPLOAD_STATUS.Success) return undefined;
    return `${uploadProgress}%`;
  };

  /**重新上传文件 */
  const redoClick = async () => {
    if (!file) return;
    const startUploadTime = Date.now();
    let parseStartTime = 0;
    try {
      const md5 = await upload(file);
      const uploadTime = Date.now() - startUploadTime;
      // 上传成功埋点
      sendEvent('CompleteEnhanceDocument', {
        status: 'success',
        fileName: file.name,
        fileType,
        uploadTime,
      });
      if (md5) {
        parseStartTime = Date.now();
        await parsingFile(md5);
        const parseTime = Date.now() - parseStartTime;
        // 解析成功埋点
        sendEvent('CompleteParseEnhanceDocument', {
          parseStatus: 'success',
          fileName: file.name,
          fileType,
          parseTime,
        });
      }
    } catch (error: any) {
      if (error.message == 'upload failed') {
        const uploadTime = Date.now() - startUploadTime;
        // 上传失败埋点
        sendEvent('CompleteEnhanceDocument', {
          status: 'failed',
          fileName: file.name,
          fileType,
          uploadTime,
        });
      }
      if (error.message == 'parse failed') {
        // 解析失败
        const parseTime = Date.now() - parseStartTime;
        sendEvent('CompleteParseEnhanceDocument', {
          parseStatus: 'failed',
          fileName: file?.name,
          fileType,
          parseTime,
        });
      }
    }
  };

  if (!file) return null;
  return (
    // <div
    //   className={classNames({
    //     'file-upload-status': true,
    //     failed: uploadStatus === UPLOAD_STATUS.Failed,
    //   })}
    // >
    //   <CustomIcon className="file-icon" type={`${fileType}Big`} />
    //   <div className="file-info">
    //     <h2 className="ellipsis">{file?.name ?? ''}</h2>
    //     <p>{statusText()}</p>
    //   </div>
    //   {(uploadStatus === UPLOAD_STATUS.Uploading ||
    //     uploadStatus === UPLOAD_STATUS.Parsing) && (
    //     <div className="file-progress">
    //       {uploadProgress}%
    //       <Progress
    //         type="circle"
    //         percent={uploadProgress}
    //         size={15}
    //         strokeColor={'#3C38FF'}
    //         strokeWidth={13}
    //       />
    //     </div>
    //   )}
    //   {uploadStatus === UPLOAD_STATUS.Failed && (
    //     <CustomIcon className="file-redo" type="redo" onClick={redoClick} />
    //   )}
    //   {(uploadStatus === UPLOAD_STATUS.Success ||
    //     uploadStatus === UPLOAD_STATUS.Failed) && (
    //     <CustomIcon className="file-delete" type="close" onClick={reset} />
    //   )}
    // </div>
    <File
      isReady={uploadStatus === UPLOAD_STATUS.Success || uploadStatus === UPLOAD_STATUS.Failed}
      content={{
        file_name: file?.name ?? '',
        file_type: fileType || file?.type || 'pdf',
        file_desc: statusText(),
        file_page_count: fileExtraInfo?.page_count ?? 1,
      }}
      showProcess={
        uploadStatus === UPLOAD_STATUS.Uploading || uploadStatus === UPLOAD_STATUS.Parsing
      }
      percent={uploadProgress}
      errorMsg={uploadStatus === UPLOAD_STATUS.Failed ? uploadError : undefined}
      onRedo={redoClick}
      onRest={reset}
    />
  );
};

export default FileUploadStatus;
