import { PromptTemplate } from '@/type';
import CustomIcon from '@/components/CustomIcon';

interface SearchTemplateProps {
  title: string;
  templates?: PromptTemplate[];
  onSelectTpl?: (tpl: PromptTemplate) => void;
}
const SearchTemplate: React.FC<SearchTemplateProps> = (props) => {
  const { templates = [] } = props;

  const getTplIcon = (tplId?: number) => {
    switch (tplId) {
      // ppt
      case 20000:
        // return <img src={TplIcon} alt="" />;
        return <CustomIcon type="popai-presentation" />;
      // writing
      case 120013:
        return <CustomIcon type="popai-a-AIwriting" />;
      default:
        return <CustomIcon type="popai-grid" />;
    }
  };
  return (
    <div className="searchtpl">
      <h1>{props.title}</h1>
      {templates.map((tpl: PromptTemplate) => (
        <div className="searchtpl-item" key={tpl?.name} onClick={() => props?.onSelectTpl?.(tpl)}>
          <div className="icon">{getTplIcon(tpl?.id)}</div>
          <p>{tpl?.name}</p>
        </div>
      ))}
    </div>
  );
};

export default SearchTemplate;
