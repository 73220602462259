import { useChatStore, useCommonStore } from '@/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDA, useSundry } from '@/hooks';
import type { SendControllerViewProps } from '../types';
import { gpt4oName, proChatModelName } from '@/common/model';
import { AB_EXPERIMENTS } from '@/common/config';
const useSwtichRag = (
  props: Pick<SendControllerViewProps, 'channelId' | 'searchSwitch' | 'onSwitchRagChange'>,
) => {
  const { sendDAEvent } = useDA();
  const { experiment } = useSundry();
  const removeGPT4Feature = experiment(AB_EXPERIMENTS.REMOVEGPT4);

  const [innerSwitchChecked, setInnerSwitchChecked] = useState(true);

  const { showOneChatGUI, switchRagDisabled } = useChatStore((state) => ({
    showOneChatGUI: state.showOneChatGUI,
    switchRagDisabled: state.switchRagDisabled,
  }));
  const { chatModel, setChatModel } = useCommonStore((state) => ({
    chatModel: state.chatModel,
    setChatModel: state.setChatModel,
  }));

  const switchChecked = useMemo(() => {
    if (props?.channelId) return props?.searchSwitch;
    return innerSwitchChecked;
  }, [innerSwitchChecked, props?.searchSwitch, props?.channelId]);

  const switchRagSize: 'small' | 'default' = useMemo(() => {
    if (props?.channelId && showOneChatGUI) return 'small';
    return 'default';
  }, [props?.channelId, showOneChatGUI]);

  // 是否可以切换联网开关
  const canSwitchRag: boolean = useMemo(() => {
    if (props?.channelId === undefined) {
      return true;
    }
    return !switchRagDisabled;
  }, [props?.channelId, switchRagDisabled]);

  const handleSwitchRag = useCallback(
    (checked: boolean) => {
      if (props?.channelId) {
        props?.onSwitchRagChange?.(checked);
      } else {
        sendDAEvent('OnlineSearch_Switch', {
          channelid: '',
          chattype: '',
          is_on: checked,
        });
        setInnerSwitchChecked(checked);
        if (checked && chatModel === proChatModelName) {
          setChatModel(gpt4oName);
        }
      }
    },
    [props, sendDAEvent, chatModel, setChatModel],
  );

  useEffect(() => {
    if (chatModel === proChatModelName && removeGPT4Feature !== true) {
      // 去除undefind情况
      setInnerSwitchChecked(false);
    } else if (removeGPT4Feature === true && chatModel === proChatModelName) {
      setInnerSwitchChecked(true);
    }
  }, [chatModel, removeGPT4Feature]);

  return {
    switchChecked,
    switchRagSize,
    canSwitchRag,
    handleSwitchRag,
  };
};

export default useSwtichRag;
