import { type MouseEventHandler, type HTMLAttributes } from 'react';
import CustomIcon from '@/components/CustomIcon';
import cs from 'classnames';

import './index.less';

const AddFileItemUi: React.FC<
  {
    onClick?: MouseEventHandler;
    iconType?: string | null;
    text?: string;
    children?: React.ReactNode;
  } & HTMLAttributes<HTMLDivElement>
> = ({ onClick, iconType, text, className, children }) => {
  return (
    <div className={cs('url-btn add-file-item', className)} onClick={onClick}>
      {iconType && <CustomIcon type={iconType} className="link-icon" />}
      <div className="link-text">{text}</div>
      {children}
    </div>
  );
};

export default AddFileItemUi;
