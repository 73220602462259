import { Tooltip } from 'antd';
import cls from 'classnames';
import './index.less';

interface Props {
  tipText?: string;
  children: React.ReactNode | React.ReactNode[];
  style?: React.CSSProperties;
  showTip?: boolean | undefined;
  containerCls?: string;
}
const HoverShadow: React.FC<Props> = (props) => {
  const { children, tipText, style, showTip, containerCls } = props;
  const Element = (
    <div className={cls('hover-shadow-container', containerCls)} style={style}>
      {children}
    </div>
  );
  if (tipText) {
    return (
      <Tooltip
        open={showTip}
        zIndex={0}
        title={tipText}
        overlayInnerStyle={{
          borderRadius: '8px',
          fontSize: '13px',
          lineHeight: '18px',
        }}
      >
        {Element}
      </Tooltip>
    );
  }
  return Element;
};

export default HoverShadow;
