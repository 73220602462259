import { getMultiDocMaxCount } from '@/common/config';
import {
  useChatStore,
  useCommonStore,
  useEnhanceUploadStore,
  useSendUploadStore,
  useUserStore,
} from '@/store';
import { PromptTemplate } from '@/type';
import { TooltipPlacement } from 'antd/es/tooltip';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SendControllerViewProps } from '../types';

const useSendFileUpload = (
  props: Pick<
    SendControllerViewProps,
    'quoteFiles' | 'channelId' | 'selectedImageProgress' | 'selectedImage'
  > & {
    realTemplate?: PromptTemplate;
    canAddImg?: boolean;
  },
) => {
  const [addFilesPopoverOpen, setAddFilesPopoverOpen] = useState(false);

  const { allUploadFiles, isUploadImage, imageUrls, setExceedConfirmOpen, setuploadMethod } =
    useSendUploadStore((state) => ({
      allUploadFiles: state.allUploadFiles,
      isUploadImage: state.isUploadImage,
      imageUrls: state.imageUrls,
      setExceedConfirmOpen: state.setExceedConfirmOpen,
      setuploadMethod: state.setuploadMethod,
    }));
  const { file } = useEnhanceUploadStore((state) => ({
    file: state.file,
  }));
  const { userInfo, guestToken } = useUserStore((state) => ({
    userInfo: state.userInfo,
    guestToken: state.guestToken,
  }));
  const { isRTLDir } = useCommonStore((state) => ({
    isRTLDir: state.isRTLDir,
  }));
  const { showOneChatGUI } = useChatStore((state) => ({
    showOneChatGUI: state.showOneChatGUI,
  }));

  const maxFilesCount = getMultiDocMaxCount(userInfo, guestToken);

  // 增加文件按钮是否可用,
  const canAddFiles = useMemo(() => {
    // 已经创建了channel不可用
    if (props?.channelId && props?.realTemplate?.mediaType === 'ppt') return false;
    // ppt模版下，enhance上传file存在，不可用
    if (props?.realTemplate?.mediaType === 'ppt' && file?.uid) return false;
    // 有图片不可用
    if (isUploadImage || imageUrls.length > 0) return false;
    // 非PPT模版不可用
    if (props?.realTemplate?.id && props?.realTemplate?.mediaType !== 'ppt') return false;
    // 普通上传文件数超过限制不可用
    if (allUploadFiles.length >= maxFilesCount) return false;
    if (props?.selectedImage?.file_name || props?.selectedImageProgress) return false;
    // 有引用文档（目前只存在于writing生成ppt） 不允许上传
    if (props?.quoteFiles) return false;
    return true;
  }, [
    props?.channelId,
    props?.realTemplate?.mediaType,
    props?.realTemplate?.id,
    props?.selectedImage?.file_name,
    props?.selectedImageProgress,
    props?.quoteFiles,
    file?.uid,
    isUploadImage,
    imageUrls.length,
    allUploadFiles.length,
    maxFilesCount,
  ]);

  const showAddFiles = useMemo(() => {
    // 有引用文档（目前只存在于writing生成ppt） 不展示上传
    if (props?.quoteFiles) return false;
    return true;
  }, [props?.quoteFiles]);

  // 上传文件的模式
  const addFileUploadType: 'enhance' | 'normal' = useMemo(() => {
    if (props?.realTemplate?.mediaType === 'ppt') {
      return 'enhance';
    }
    return 'normal';
  }, [props?.realTemplate?.mediaType]);

  const addFilPlacement: TooltipPlacement | undefined = useMemo(() => {
    return showOneChatGUI ? (isRTLDir ? 'topRight' : 'topLeft') : 'top';
  }, [showOneChatGUI, isRTLDir]);

  const handleAddFilesPopover = useCallback(
    (status?: boolean) => {
      if (!canAddFiles && !props?.canAddImg) return false;
      if (!status) {
        setAddFilesPopoverOpen(!addFilesPopoverOpen);
      } else {
        setAddFilesPopoverOpen(status);
        setuploadMethod();
      }
      // setSearchTplShow(false);
    },
    [canAddFiles, props?.canAddImg, addFilesPopoverOpen, setuploadMethod],
  );

  useEffect(() => {
    if (allUploadFiles.length > maxFilesCount) {
      setExceedConfirmOpen(true);
    }
  }, [allUploadFiles.length, maxFilesCount, setExceedConfirmOpen]);

  return {
    showAddFiles,
    canAddFiles,
    addFileUploadType,
    addFilPlacement,
    maxFilesCount,
    addFilesPopoverOpen,
    setAddFilesPopoverOpen,
    handleAddFilesPopover,
  };
};

export default useSendFileUpload;
