import { basicChatModelName } from '@/common/model';
import { FinishUploadParams, ImageUploadRef } from '@/components/ImageUpload';
import { useGASendEvent, useProgress } from '@/hooks';
import { useChatStore, useSendUploadStore } from '@/store';
import { PromptTemplate } from '@/type';
import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { EChatType, SendControllerViewProps } from '../types';

const useUploadImage = (
  props: Pick<
    SendControllerViewProps,
    'channelId' | 'chatType' | 'selectedImageProgress' | 'selectedImage' | 'canScreenShot'
  > & {
    realTemplate?: PromptTemplate;
  },
  imageUploadRef: MutableRefObject<ImageUploadRef | null>,
) => {
  // hooks
  const { sendEvent } = useGASendEvent();
  const { progress, start, stop } = useProgress();

  // store
  const {
    allUploadFiles,
    imageUrls,
    imageFileNames,
    isUploadImage,
    imgProgress,
    onReset,
    setIsUploadImage,
    setImageUrls,
    setImgProgress,
    setImageFileNames,
  } = useSendUploadStore((state) => ({
    allUploadFiles: state.allUploadFiles,
    imageUrls: state.imageUrls,
    isUploadImage: state.isUploadImage,
    imgProgress: state.imgProgress,
    imageFileNames: state.imageFileNames,
    onReset: state.onReset,
    setIsUploadImage: state.setIsUploadImage,
    setImageUrls: state.setImageUrls,
    setImgProgress: state.setImgProgress,
    setImageFileNames: state.setImageFileNames,
  }));

  const { channelModel, showOneChatGUI } = useChatStore((state) => ({
    channelModel: state.channel?.modelName,
    showOneChatGUI: state.showOneChatGUI,
  }));

  // 增加图片按钮是否可用
  const canAddImg = useMemo(() => {
    // 图片上传中、有模版、上传了一个图片、AT选择模版展开时、有引用时,上传了文件时，均不可用
    if (
      props?.selectedImageProgress ||
      props?.selectedImage ||
      isUploadImage ||
      (props?.realTemplate?.id && props?.realTemplate?.connectionName !== 'CHATIMAGE') ||
      imageUrls.length > 0 ||
      allUploadFiles.length > 0 ||
      (props?.channelId && channelModel === basicChatModelName) //standard不支持识图,因此在对话内并且命中灰度并且对话模型是standard，不允许上传图片
    )
      return false;
    return true;
  }, [
    props?.selectedImageProgress,
    props?.selectedImage,
    props?.realTemplate?.id,
    props?.realTemplate?.connectionName,
    props?.channelId,
    isUploadImage,
    imageUrls.length,
    allUploadFiles.length,
    channelModel,
  ]);

  // 是否展示上传图片按钮, 命中灰度且不是creation对话不展示（因为命中灰度，上传图片的按钮挪进了上传图片的popover中）
  const showAddImg = useMemo(() => {
    if (props?.chatType === EChatType.chatWithTPL) return true;
    return false;
  }, [props?.chatType]);

  // 是否展示截取图片
  const showCaptureImg = useMemo(() => {
    return showOneChatGUI && props?.channelId;
  }, [showOneChatGUI, props?.channelId]);

  const canCapture = useMemo(() => {
    if (props?.realTemplate?.id || allUploadFiles.length > 0) return false;
    return props?.canScreenShot;
  }, [props?.realTemplate?.id, allUploadFiles.length, props?.canScreenShot]);

  const handleStartImageUpload = useCallback(() => {
    sendEvent('Click_Picture_Open');
    start();
    setIsUploadImage(true);
    // 上传开始时，清空图片
    setImageUrls([]);
    setImageFileNames([]);
    // 图片上传就清空file
    allUploadFiles.length > 0 && onReset();
  }, [
    sendEvent,
    start,
    setIsUploadImage,
    setImageUrls,
    setImageFileNames,
    allUploadFiles.length,
    onReset,
  ]);

  const handleFinishImageUpload = useCallback(
    (params?: FinishUploadParams) => {
      stop();
      if (params?.imageUrls && params?.imageUrls.length > 0) {
        setImageUrls(params.imageUrls);
        params?.name && setImageFileNames([params.name]);
      }
      setIsUploadImage(false);
    },
    [stop, setIsUploadImage, setImageUrls, setImageFileNames],
  );

  const handleRemoveImages = useCallback(() => {
    // setCancelUploadImg(true);
    imageUploadRef.current?.uploadAbort?.();
    setImageUrls([]);
    setImageFileNames([]);
    setIsUploadImage(false);
    setImgProgress(0);
  }, [imageUploadRef, setImageUrls, setImageFileNames, setIsUploadImage, setImgProgress]);

  useEffect(() => {
    setImgProgress(progress);
  }, [progress, setImgProgress]);

  return {
    imageUrls,
    isUploadImage,
    imgProgress,
    imageFileNames,
    canAddImg,
    showAddImg,
    showCaptureImg,
    canCapture,
    handleStartImageUpload,
    handleFinishImageUpload,
    handleRemoveImages,
  };
};

export default useUploadImage;
