import axios from '@/common/request';
import type { AxiosRequestConfig, CancelTokenSource } from 'axios';
import type { Presentation_Themes, PresentationContent } from '@/components/PresentationView';
import { FEEDBACK_TYPE } from '@/common/config';
import type { ResponseType, LandingChannel, Channel } from '@/type';
interface ATtraceIdProps {
  at_trace_id?: string;
}

export async function getMessages<T extends ATtraceIdProps>(
  params: {
    beforeMessageId?: string;
    channelId: string;
    pageSize: number;
  },
  options: AxiosRequestConfig,
) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(
    `/api/v1/chat/messages?${encodeURI(paramArr.join('&'))}`,
    options,
  );

  // 读取请求头，将请求头里面的logid读取出来，塞到响应结果里面（message）
  result.data.at_trace_id = result?.headers?.['x-log-id'] as string;
  return result.data;
}

export async function saveMessage<T>(params: { content: string; messageId: string }) {
  const result = await axios.post<T>('/api/v1/chat/saveMessages', params);
  return result.data;
}

// 快速在channel间切换，需要把上一个getFile请求abort掉
let getFileController: AbortController | null = null;

export async function getFileUsingGET<T>(params: { md5: string; channelId: string }) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  // abort上一个getFile请求
  getFileController?.abort();
  // 创建新的controller
  getFileController = new AbortController();
  const result = await axios.get<T>(`/py/api/v1/chat/getFile?${encodeURI(paramArr.join('&'))}`, {
    signal: getFileController.signal,
    noAlertError: true,
  });
  return result.data;
}

export async function getFeedbackConfig<T>() {
  const result = await axios.get<T>(`/api/v1/feedback/config`);
  return result.data;
}

export async function upsertFeedbackUsingPOST<T>(params: {
  feedbackRequest: {
    content?: string;
    messageId: string;
    tagEnumList?: number[];
    typeEnum: FEEDBACK_TYPE;
  };
}) {
  const result = await axios.post<T>(`/api/v1/feedback`, {
    ...params.feedbackRequest,
  });
  return result.data;
}

export async function upsertChatFeedback<T>(params: {
  feedbackRequest: {
    content?: string;
    messageId: string;
    tagEnumList?: string[];
    typeEnum: FEEDBACK_TYPE;
    category: string;
  };
}) {
  const result = await axios.post<T>(`/api/v1/feedback/chat`, {
    ...params.feedbackRequest,
  });
  return result.data;
}

export async function removeFeedbackUsingPOST<T>(params: {
  feedbackRequest: {
    content?: string;
    messageId: string;
    tagEnumList?: number[];
    typeEnum: FEEDBACK_TYPE;
  };
}) {
  const result = await axios.post<T>(`/api/v1/feedback/delete`, {
    ...params.feedbackRequest,
  });
  return result.data;
}

export async function saveSources<T>(params: { channelId: string; sources: string }) {
  const result = await axios.post<T>('/api/v1/channel/updateSources', params);
  return result.data;
}

export async function getPptThemes<T>() {
  const result = await axios.get<T>(`/api/v1/channel/getThemes`);
  return result.data;
}

export async function savePptTheme<T>(params: {
  channelId: string;
  themeName: Presentation_Themes;
}) {
  const result = await axios.post<T>('/api/v1/channel/updateThemeName', params);
  return result.data;
}

/**
 * 保存PPT内容
 *
 * @param params - 包含以下参数的对象
 * @param outlineEdit - 是否编辑大纲
 * @param slidesEdit - 是否编辑幻灯片
 * @param channelId - 频道ID
 * @param outline - 大纲内容
 * @param slides - 幻灯片内容
 * @param options - Axios请求配置选项（可选）
 * @returns 返回axios.post的响应结果
 */
export async function savePptContent<T>(
  params: {
    channelId: string;
    outlineEdit?: boolean;
    slidesEdit?: boolean;
    outline?: PresentationContent;
    slides?: PresentationContent;
  },
  options?: AxiosRequestConfig,
) {
  const result = await axios.post<T>('/api/v1/chat/editChannelPPT', params, options);
  return result.data;
}

export async function chooseMessage<T>(params: { channelId: string; messageId: string }) {
  const result = await axios.post<T>('/api/v1/chat/chooseControlGroupMessage', params);
  return result.data;
}

export async function chooseMessageEnrich<T>(params: { textEnrich: string; messageId: string }) {
  const result = await axios.post<T>('/api/v1/chat/chooseMessageTextEnrich', params);
  return result.data;
}

export async function randomChangePPTImage<T>(params: {
  channelId: string;
  nodeId: string;
  originalImgUrl: string;
}) {
  const result = await axios.post<T>('/api/v1/chat/randomChangePPTImage', params);
  return result.data;
}

export async function getDocSummaryTips<T>() {
  const result = await axios.get<T>(`/api/v1/chat/docTips`);
  return result.data;
}

export async function getAdvanceConfigSettings<T>(params: {
  promptTemplateId?: number;
  groupKeys: string[];
}) {
  const result = await axios.post<T>(`/api/v1/prompt/getDicByGroups`, params);
  return result.data;
}

export async function getDebugMsg<T>(params: { msgId?: string }) {
  const result = await axios.get<T>(`/api/v1/chat/getDebugMsg`, { params });
  return result.data;
}

export async function getExampleChannels() {
  const result = await axios.get<ResponseType<LandingChannel[]>>(
    '/api/v1/landing/presetChannelList',
  );
  return result.data;
}

export async function cloneChannel(channelId: string, model: string) {
  const result = await axios.post<ResponseType<Channel>>(`/api/v1/chat/createChannel`, {
    channelId,
    model,
  });
  return result.data;
}

export async function uploadFileByUrl<T>(params: { url?: string }) {
  const result = await axios.get<T>(`/py/api/v1/chat/getFileByUrl`, { params });
  return result.data;
}

/** 获取首页推荐 */
export async function getHomeQuestionList<T>(source: CancelTokenSource) {
  const result = await axios.get<T>(`/api/v1/landing/presetQuestionList`, {
    cancelToken: source.token,
  });
  return result.data;
}
