export enum InputSuggestionValueType {
  TEXT,
}

export enum InputTypingState {
  TYPING,
  PASTING_TEXT,
}

export enum InputControlState {
  NONE,
  USER_PROMPT,
  PPT_TEMPLATE,
  FILE,
  IMAGE,
}

export interface InputContrlProps {
  /**
   * set value callback, and the parent component can decide how to accept the suggestion
   */
  onSuggestion?: (value: string, type?: InputSuggestionValueType) => void;
  /**
   * default active state for the control
   */
  active?: boolean;
  /**
   * Control use this state to detect typing or pasting
   */
  inputTypingState?: InputTypingState;
  /**
   * which control is active for the input,
   *
   * used to decide if current control should run auto tasks
   */
  // inputControlState?: InputControlState;
}
