/**
 * 通过files（原workspace）上传文档
 * 用于chat with doc二级页、输入框上传文档
 */

import WorkspaceModal from '../WorkspaceModal';
import { Channel, EUploadFromFilesType, EUploadScene } from '@/type';
import useUploadFromFiles from './useUploadFromFiles';
import CustomIcon from '../CustomIcon';
import { useTranslation } from 'react-i18next';

export interface UploadFromFilesProps {
  type: EUploadFromFilesType[number];
  children?: React.ReactNode;
  /**上传使用场景，normal：普通上传，enhance： enhance ppt */
  uploadScene?: EUploadScene[number];
  /**是否是多选 */
  multiSelection?: boolean;
  /**当前使用的模型 */
  currentModel?: string;
  /**channel信息，主要用作埋点 */
  channel?: Channel;
}

const UploadFromFiles = <T,>(props: UploadFromFilesProps & { onAdd: (files: T[]) => void }) => {
  const { children, multiSelection = true, channel } = props;

  const { showWorkspaceModal, selectedIds, handleCancel, handleConfirm, handleBtnClick } =
    useUploadFromFiles(props);
  const { t } = useTranslation();

  return (
    <div>
      <div onClick={handleBtnClick}>
        {props?.type === EUploadFromFilesType.docPage && (
          <CustomIcon tooltip={t('pages.upload.files')} type="popai-a-workspace1" />
        )}
        {props?.type === EUploadFromFilesType.chatInput && children}
      </div>
      {showWorkspaceModal && (
        <WorkspaceModal
          multiSelection={multiSelection}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          selected={selectedIds}
          channel={channel}
        />
      )}
    </div>
  );
};

export default UploadFromFiles;
