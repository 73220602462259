/*
 * @Author: wubo
 * @Date: 2023-06-15 15:06:42
 * @LastEditTime: 2023-09-06 17:07:25
 * @LastEditors: wubo
 * @Description:
 */
import ReactDOM from 'react-dom/client';
import { App, ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Routes from '@/router';
import { AxiosInterceptor } from './common/request';
import ErrorBoundary from '@/components/ErrorBoundary';
import AdConversion from '@/components/AdConversion';
import Cookies from 'js-cookie';
import { initMonitor } from './common/monitor';
import './index.less';
import './antd-override.less';

// 下边是国际化引入
import './i18n';
import { ConfigProviderProps } from 'antd/lib';
import useI18n from '@/hooks/useI18n';

import { isMobile } from './common/utils';
import { useDA } from './hooks';

const mobile = isMobile();

// 移动端跳转，用location.href避免走前端路由而无法打开m站
// terms页面不跳转
if (
  mobile &&
  window.location.pathname !== '/m/' &&
  !window.location.pathname.startsWith('/terms/') &&
  window.location.pathname !== '/contact'
) {
  window.location.href = `/m/${window.location.search}`;
}

const config: ConfigProviderProps = {
  prefixCls: 'chat',
  theme: {
    token: {
      colorPrimary: '#3C38FF',
    },
  },
};

const appConfig = {
  message: {
    maxCount: 1,
    top: 13,
  },
};

Promise.resolve().then(() => {
  initMonitor();
});

// 联盟运营计划-第一期 注册接口增加ref字段，经讨论 统一增加到cookie中，
const urlSearchParams = new URLSearchParams(window.location.search);
const refParam = urlSearchParams.get('ref');
if (refParam) {
  Cookies.set('partner_ref', refParam, { domain: import.meta.env.VITE_APP_DOMAIN });
}

const APP = () => {
  const { antdLocale, locale: localeName } = useI18n();

  useDA();

  return (
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_APP_GOOGLE_CLIENT_ID}>
        <ConfigProvider {...config} locale={antdLocale}>
          {/* <RuntimeProvider> */}
          <App {...appConfig}>
            <AdConversion />
            <AxiosInterceptor>
              <BrowserRouter basename={localeName || ''}>
                <Routes />
              </BrowserRouter>
            </AxiosInterceptor>
          </App>
          {/* </RuntimeProvider> */}
        </ConfigProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
};
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<APP />);
