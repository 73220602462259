import { memo } from 'react';

import styles from './welcome.module.less';
import { useTranslation } from 'react-i18next';
import { MyPromptsBanner, SavePromptBanner } from '@/components/UserPrompt';

const UserPromptWelcome = memo(() => {
  const { t } = useTranslation();
  return (
    <div className={styles.ct}>
      <div className={styles.step}>
        <div className={styles.text}>
          <div className={styles.step__header}>{t('components.userPrompt.getStartedStep1')}</div>
          <div className={styles.step_desc}>{t('components.userPrompt.getStarted1')}</div>
        </div>
        <SavePromptBanner />
      </div>
      <div className={styles.step}>
        <div className={styles.text}>
          <div className={styles.step__header}>{t('components.userPrompt.getStartedStep2')}</div>
          <div className={styles.step_desc}>{t('components.userPrompt.getStarted2')}</div>
        </div>
        <MyPromptsBanner />
      </div>
    </div>
  );
});

export default UserPromptWelcome;
