import { memo } from 'react';
import InputControlView from './View';
import { InputContrlProps } from '../types';

const UserPromptOperatorControl = memo(
  ({
    active,
    onClick,
    text,
    toolTip,
  }: InputContrlProps & {
    onClick: (event: React.MouseEvent) => void;
    text?: string;
    toolTip?: string;
  }) => {
    return (
      <InputControlView
        tooltip={toolTip}
        iconType="popai-slash"
        active={active}
        onClick={onClick}
        text={text}
      />
    );
  },
);

export default UserPromptOperatorControl;
