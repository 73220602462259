import { PromptTemplate } from '@/type';
import CustomIcon from '@/components/CustomIcon';
import { useTranslation } from 'react-i18next';
import { DEFAULT_THUMBNAIL } from '../constants';

interface TemplateOverviewProps {
  template?: PromptTemplate;
  closeIconType?: string;
  handleTemplatePreview: () => void;
  handleCloseTemplateMode: () => void;
}

const TemplateOverview: React.FC<TemplateOverviewProps> = ({
  template = {},
  closeIconType = 'close',
  handleTemplatePreview,
  handleCloseTemplateMode,
}) => {
  const { t } = useTranslation();
  return (
    <div className="template-overview">
      <div className="template-cover">
        {!template?.thumbnail || template?.thumbnail === DEFAULT_THUMBNAIL ? (
          <span className="template-cover-fake-text">{template?.name?.slice(0, 1)}</span>
        ) : (
          <img src={template?.thumbnail === DEFAULT_THUMBNAIL ? '' : template?.thumbnail} />
        )}
        {template?.shareKey && (
          <div className="preview" onClick={handleTemplatePreview}>
            <CustomIcon type="preview" onClick={handleTemplatePreview} />
            <div>{t('common.preview')}</div>
          </div>
        )}
      </div>
      <div className="template-info">
        <h2>{template?.name}</h2>
        <p className="ellipsis">{template?.teaser}</p>
      </div>
      <CustomIcon className="close" type={closeIconType} onClick={handleCloseTemplateMode} />
    </div>
  );
};

export default TemplateOverview;
