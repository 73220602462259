import { useEffect, useMemo, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { Spin, Form, Divider, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import type {
  ResponseType,
  AdvanceConfigSettings,
  AdvanceConfigSettingItem,
  CreateChannelOptions,
} from '@/type';
import { useUserStore, useChatStore, useCommonStore, GptUsageItem } from '@/store';
import { getAdvanceConfigSettings } from '@/services';
import SettingSelectItem from './components/SettingSelectItem';

import './index.less';
import UsageTip from '../UsageTip';
import { AB_EXPERIMENTS } from '@/common/config';
import { useSundry } from '@/hooks';
import CustomIcon from '../CustomIcon';
import classNames from 'classnames';

const WRITING_GROUP: Record<string, string> = {
  writerWordAmount: 'wordAmount',
  writerForm: 'form',
};

const GROUP_KEYS = Object.keys(WRITING_GROUP);

export interface WritingControllerRef {
  getValues: () => CreateChannelOptions;
}

const WritingController = forwardRef<WritingControllerRef>((_, ref) => {
  const { userInfo } = useUserStore();
  const { usageInfo } = useCommonStore();
  const { writingSettings, setWritingSettings } = useChatStore();
  const { t } = useTranslation();
  const { experiment } = useSundry();
  const memberShipFeature = experiment(AB_EXPERIMENTS.MEMBERSHIP);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const settingRef = useRef<HTMLDivElement>(null);
  const [fillContent, setFillContent] = useState(false);

  const [wordCountLimited] = useState(['Medium-long (1000-2000 words)', 'Long (2000-3000+ words)']);
  const [formLimited] = useState(['Paper', 'Report']);

  const writeLongUsage = usageInfo.writeLong as GptUsageItem;
  const writePaperReportUsage = usageInfo.writePaperReport as GptUsageItem;

  const writingLongAvailableQuota = useMemo(() => {
    return writeLongUsage?.total - writeLongUsage?.used;
  }, [writeLongUsage?.total, writeLongUsage?.used]);

  const writingFromAvailableQuota = useMemo(() => {
    return writePaperReportUsage?.total - writePaperReportUsage?.used;
  }, [writePaperReportUsage?.total, writePaperReportUsage?.used]);

  const isShowWordCountTip = useMemo(() => {
    return memberShipFeature && !userInfo?.isUnlimited;
  }, [memberShipFeature, userInfo?.isUnlimited]);

  const isShowFormTip = useMemo(() => {
    return memberShipFeature && !userInfo?.isUnlimited;
  }, [memberShipFeature, userInfo?.isUnlimited]);

  useEffect(() => {
    if (!isLoading && settingRef.current) {
      const fittedWidth = 5 * 140 + 8 * 4;
      if (settingRef.current.offsetWidth > fittedWidth) {
        setFillContent(true);
      }
    }
  }, [isLoading]);

  useImperativeHandle(ref, () => ({
    getValues: () => form.getFieldsValue(),
  }));

  const fields = useMemo(() => {
    if (!writingSettings) {
      return [];
    }

    const fields = Object.entries(writingSettings as AdvanceConfigSettings).map(([key, value]) => {
      return {
        name: WRITING_GROUP[key],
        value: value?.find((item: AdvanceConfigSettingItem) => item.default)?.code,
      };
    });
    return fields;
  }, [writingSettings]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const { code, data } = await getAdvanceConfigSettings<ResponseType<AdvanceConfigSettings>>({
          // writing promptTemplateId没作用，后端要求写死
          promptTemplateId: 20000,
          groupKeys: GROUP_KEYS,
        });
        if (code === 200) {
          setWritingSettings(data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSettings();
  }, [userInfo?.isLogin]);

  const wordAmountOptionRender = (option: any) => {
    return (
      <div className="option-container">
        <span>{option.name}</span>
        {memberShipFeature && wordCountLimited.includes(option.code) && (
          <UsageTip type="writing" subType="wordCount" />
        )}
      </div>
    );
  };

  const formOptionRender = (option: any) => {
    return (
      <div className="option-container">
        <span>{option.name}</span>
        {memberShipFeature && formLimited.includes(option.code) && (
          <UsageTip type="writing" subType="form" />
        )}
      </div>
    );
  };

  const wordCountLabelRender = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{t('components.write.wordCount')}</span>
        {isShowWordCountTip && (
          <>
            <Divider type="vertical" />
            <span className="word-count-tip">
              <span
                className={classNames('num-base', {
                  zero: !writingLongAvailableQuota,
                })}
              >
                {writingLongAvailableQuota}
              </span>{' '}
              {t('components.write.longTextTotal')}
            </span>
            <Popover
              content={t('components.write.wordCountchances_to_use', {
                count: writeLongUsage?.total || 0,
              })}
              trigger="click"
              placement="bottom"
              overlayStyle={{ maxWidth: '250px' }}
            >
              <CustomIcon type="tip" className="word-count-tip-icon" />
            </Popover>
          </>
        )}
      </div>
    );
  };

  const formLabelRender = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{t('components.write.form')}</span>
        {isShowFormTip && (
          <>
            <Divider type="vertical" />
            <span className="word-count-tip">
              <span
                className={classNames('num-base', {
                  zero: !writingFromAvailableQuota,
                })}
              >
                {writingFromAvailableQuota}
              </span>{' '}
              {t('components.write.advancedTemplateTotal')}
            </span>
            <Popover
              content={t('components.write.formchances_to_use', {
                count: writePaperReportUsage?.total || 0,
              })}
              trigger="click"
              placement="bottom"
              overlayStyle={{ maxWidth: '250px' }}
            >
              <CustomIcon type="tip" className="word-count-tip-icon" />
            </Popover>
          </>
        )}
      </div>
    );
  };

  return (
    <Spin className="controller-loading" spinning={isLoading} size="small">
      {writingSettings && (
        <Form layout="vertical" form={form} fields={fields}>
          <div
            className="writing-settings"
            ref={settingRef}
            style={{
              justifyContent: fillContent ? 'space-between' : 'flex-start',
            }}
          >
            <SettingSelectItem
              label={wordCountLabelRender()}
              name="wordAmount"
              form={form}
              options={writingSettings.writerWordAmount}
              optionRender={wordAmountOptionRender}
            />
            <SettingSelectItem
              label={formLabelRender()}
              name="form"
              form={form}
              options={writingSettings.writerForm}
              optionRender={formOptionRender}
            />
          </div>
        </Form>
      )}
    </Spin>
  );
});

export default WritingController;
