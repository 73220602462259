import { Skeleton } from 'antd';
import { lazy, Suspense, useState } from 'react';
import { Helmet } from 'react-helmet';
import { throttle } from 'lodash-es';
import SendControllerView from '@/components/SendControllerView';
import PageSlogan from '@/components/PageSlogan';
import Card from './components/Card';
import CradNew from './components/Card_new';
import ReleaseNote from './components/ReleaseNote';
import { useChannel } from './useHome';
import { useList } from './useList';

import './index.less';
import { useEffect, useMemo } from 'react';
import { useDA, useSundry } from '@/hooks';
import { extractPathName } from '@/common/utils';
import { AB_EXPERIMENTS } from '@/common/config';
import { useTranslation } from 'react-i18next';
import DiscountBanner from '@/layout/BasicLayout/components/PayModal/DiscountBanner';
import CustomIcon from '@/components/CustomIcon';

const UpgradeNotificationModal = lazy(() => import('@/components/UpgradeNotification'));

const SkeletonList = ({ count }: { count: number }) => {
  return (
    <>
      {Array(count)
        .fill('')
        .map((_, index) => (
          <div key={index}>
            <Skeleton active />
          </div>
        ))}
    </>
  );
};

const Home = () => {
  const { setPageProperty } = useDA();

  const { handleNewMessageSend } = useChannel();
  const { t } = useTranslation();
  const { currentTipGroup, updateTip, experiment } = useSundry();
  const memberShipFeature = experiment(AB_EXPERIMENTS.MEMBERSHIP);
  // 升级通知弹窗
  const [upgradeNoteModalOpen, setUpgradeNoteModalOpen] = useState(true);
  const mainpageSugFeature = experiment(AB_EXPERIMENTS.MAINPAGE_SUG);

  const { list, listLoading, cardClickHandler } = useList();

  const isShowUpgradeNoteModal = useMemo(() => {
    const _currentTip = currentTipGroup.find((item) => item?.tag === 'pro');
    const showOnce = _currentTip && Number(_currentTip?.value) === 1;
    return showOnce && upgradeNoteModalOpen;
  }, [upgradeNoteModalOpen, currentTipGroup]);

  const cardClick = useMemo(
    () =>
      throttle((card) => {
        cardClickHandler(card);
      }, 2000),
    [cardClickHandler],
  );

  const handleUpgradeNoteCancel = () => {
    setUpgradeNoteModalOpen(false);
    updateTip('pro', 0);
  };

  useEffect(() => {
    setPageProperty({ page_name: extractPathName() });
  }, []);

  return (
    <div className="home-container">
      <Helmet>
        <title>PopAi: Chat, Read, Write and Present with AI</title>
        <meta name="keywords" content="popai, ai reader, chatgpt" />
        <meta
          name="description"
          content="PopAi is a powerful AI tool that allows you to chat with any document or pdf（GPT-4 & GPT-4o） in one click, create ai powerpoint and slides with kinds of free templates and generate any AI images fast. "
        />
      </Helmet>
      <section className="page-home">
        <PageSlogan type="home" />
        <div className="page-home-discount">
          {memberShipFeature && <DiscountBanner isShowUpgradeBtn={true} />}
        </div>
        <SendControllerView
          isBottom={false}
          wrapClassName="min-wrapper-630"
          onSend={handleNewMessageSend}
        />
        <div className="home__recommend">
          <div className="home__recommend-header">
            {t('pages.home.tryTheTools')}
            {mainpageSugFeature && <CustomIcon type="TryTheTools" />}
          </div>
          <div className="home__recommend-list">
            {listLoading ? (
              <SkeletonList count={3} />
            ) : (
              list.map((item) => {
                return mainpageSugFeature ? (
                  <CradNew key={item.type} item={item} onClick={cardClick} />
                ) : (
                  <Card key={item.type} item={item} onClick={cardClick} />
                );
              })
            )}
          </div>
        </div>
        <ReleaseNote />
        {isShowUpgradeNoteModal && (
          <Suspense>
            <UpgradeNotificationModal onClose={handleUpgradeNoteCancel} />
          </Suspense>
        )}
      </section>
      <div className="blog-entry" onClick={() => window.open('https://gblog.popai.pro')}>
        <CustomIcon
          type="popai-Insights"
          text={t('pages.home.exploreBlog')}
          className="entry-icon"
        />
      </div>
    </div>
  );
};

export default Home;
