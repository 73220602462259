/*
 * @Author: wubo
 * @Date: 2023-09-22 20:26:14
 * @Description:
 * @LastEditTime: 2024-01-02 20:59:49
 * @LastEditors: zuoyuxing001
 */
import { useMemo } from 'react';
import { Space } from 'antd';
import cls from 'classnames';
// import CustomIcon from '@/components/CustomIcon';
import { useTranslation } from 'react-i18next';
import { useCommonStore } from '@/store';

interface SloganProps {
  page: 'home' | 'creation' | 'upload' | 'writing' | 'workspace' | 'more';
}

import './index.less';

const Slogan: React.FC<SloganProps> = (props) => {
  const { t } = useTranslation();
  const pageLanguage = useCommonStore((state) => state.pageLanguage);

  const { page } = props;

  const text = useMemo(() => {
    switch (page) {
      case 'home':
        return t('common.Workspace');
      case 'creation':
        return t('components.Slogan.creationSloganLabel');
      case 'upload':
        return t('components.Slogan.uploadSloganLabel');
      case 'writing':
        return t('components.Slogan.writingSloganLabel');
      case 'workspace':
        return t('components.Slogan.workspaceSloganLabel');
      case 'more':
        return t('components.Slogan.moreSloganLabel');
      default:
        return '';
    }
  }, [page, pageLanguage, t]);

  return (
    <Space
      className="brand-slogan-container"
      size={[12, 0]}
      wrap
      split={<div className="divider">|</div>}
    >
      {/* <Space className="brand-content" size={12}> */}
      {/* <CustomIcon className="brand-icon" type="logoText" /> */}
      {/* PopAi */}
      {/* {t('layout.brandName')} */}
      {/* </Space> */}
      <div className={cls('brand-slogan', 'upgrade')}>{text}</div>
    </Space>
  );
};
export default Slogan;
