import { HTMLAttributes } from 'react';
import styles from './index.module.less';
import cs from 'classnames';

/**
 * set parent container to overflow:hidden, if no absolute width provided for this component or it's parent
 */
function TextOverflow({ className, children, ...others }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cs(styles.overflow, className)} {...others}>
      {children}
    </div>
  );
}

export default TextOverflow;
