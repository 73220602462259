import { useCallback, useMemo, useState } from 'react';
import { ListItem } from '@/pages/WorkSpace/typing';
import { FileExt, getFileMineType } from '@/common/helpers/fileHelper';
import { basicChatModelName } from '@/common/model';
import { useGlobalModal } from '@/layout/BasicLayout';
import { useTranslation } from 'react-i18next';
import {
  useCommonStore,
  useUserStore,
  useSendUploadStore,
  useEnhanceUploadStore,
  useUploadStore,
} from '@/store';
import { isModelPro } from '@/common/config';
import { EUploadFromFilesType, EUploadScene } from '@/type';
import { getChatType } from '@/common/helpers/chatHelper';
import { useDA } from '@/hooks';
import { UploadFromFilesProps } from '.';

const useUploadFromFiles = <T>(props: UploadFromFilesProps & { onAdd: (files: T[]) => void }) => {
  // states
  const [showWorkspaceModal, setShowWorkspaceModal] = useState(false);
  // props
  const {
    currentModel = basicChatModelName,
    uploadScene = EUploadScene.normal,
    type = EUploadFromFilesType.chatInput,
    onAdd,
  } = props;
  // stores
  const { allUploadFiles } = useSendUploadStore((state) => ({
    allUploadFiles: state.allUploadFiles,
  }));
  const { docUploadLimitHint } = useCommonStore((state) => ({
    docUploadLimitHint: state.usageInfo.docUploadLimitHint,
  }));
  const { isPro, isTrial } = useUserStore((state) => ({
    isPro: state.userInfo.isPro,
    isTrial: state.userInfo.isTrial,
  }));
  const { fileForPPT } = useEnhanceUploadStore((state) => ({
    fileForPPT: state.file,
  }));
  const { docFiles } = useUploadStore((state) => ({
    docFiles: state.allUploadFiles,
  }));

  // hooks
  const { checkPayStatus, checkLoginStatus } = useGlobalModal();
  const { t } = useTranslation();
  const { sendDAEvent } = useDA();

  const selectedIds = useMemo(() => {
    if (type === EUploadFromFilesType.chatInput) {
      if (uploadScene === EUploadScene.enhance) {
        return [fileForPPT?.uid || ''];
      } else {
        return allUploadFiles.map((file) => file.uid);
      }
    }
    return docFiles.map((file) => file.uid);
  }, [allUploadFiles, docFiles, fileForPPT?.uid, type, uploadScene]);

  const handleCheckloginStatus = useCallback(() => {
    const res = checkLoginStatus?.({ type: 'UploadFiles_Click', model: currentModel });
    return res;
  }, [currentModel, checkLoginStatus]);

  const handleCheckPayStatus = useCallback(() => {
    // 如果是enhance，只拦截登录
    if (uploadScene === EUploadScene.enhance) return true;
    if (docUploadLimitHint) {
      checkPayStatus?.({
        shortMessage: t('common.filesPerDay', { count: 2 }),
        source: 'FREEDAYMAXFILE',
      });
      return false;
    }
    if (!isPro && !isTrial && isModelPro(currentModel)) {
      checkPayStatus({
        shortMessage: t('common.responseWith', { name: currentModel }),
        source: 'GPT4Turbo',
      });
      return false;
    }
    return true;
  }, [uploadScene, docUploadLimitHint, isPro, isTrial, currentModel, checkPayStatus, t]);

  /**
   * 按钮点击埋点
   */
  const btnClickData = useCallback(() => {
    sendDAEvent('UploadFiles_Click', {
      upload_method: 'files',
      channelid: props?.channel?.channelId || '',
      chattype: (props?.channel && getChatType(props?.channel)) || '',
    });
  }, [props?.channel, sendDAEvent]);

  const handleBtnClick = useCallback(() => {
    btnClickData();
    if (!handleCheckloginStatus()) return;
    if (!handleCheckPayStatus()) return;
    setShowWorkspaceModal(true);
  }, [btnClickData, handleCheckPayStatus, handleCheckloginStatus]);

  const handleCancel = useCallback((): void => {
    setShowWorkspaceModal(false);
  }, []);

  /**
   * 在输入框中使用files选中文件
   */
  const getNewFilesForChatInput = useCallback(
    (selected: ListItem[]) => {
      const existingIds = new Set(allUploadFiles.map((file) => file.uid));
      const newFiles = selected.reduce((ret, file) => {
        // 不要重复添加
        if (!existingIds.has(file.uniqKey)) {
          const fileInfo = {
            uid: file.uniqKey,
            md5: file.uniqKey,
            name: file.fileName,
            type: getFileMineType(
              (file.rawFileTypeEnum as unknown as FileExt) || (file.fileType as unknown as FileExt),
            ),
            pageCount: file.filePageCount,
            uploadTime: 0,
            uploadProgress: 100,
          };
          ret.push(fileInfo);
        }
        return ret;
      }, [] as typeof allUploadFiles);
      return newFiles;
    },
    [allUploadFiles],
  );

  /**
   * 在chat with doc二级页中使用files选中文件
   */
  const getNewFilesForDocPage = useCallback(
    (selected: ListItem[]) => {
      const existingIds = new Set(docFiles.map((file) => file.uid));
      const newFiles = selected.reduce((ret, file) => {
        // 不要重复添加
        if (!existingIds.has(file.uniqKey)) {
          const fileInfo = {
            uid: file.uniqKey,
            md5: file.uniqKey,
            name: file.fileName,
            fileName: file.fileName,
            type: getFileMineType(
              (file.rawFileTypeEnum as unknown as FileExt) || (file.fileType as unknown as FileExt),
            ),
            pageCount: file.filePageCount,
            uploadTime: 0,
          };
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ret.push(fileInfo);
        }
        return ret;
      }, [] as typeof docFiles);
      return newFiles;
    },
    [docFiles],
  );

  const getNewFiles = useCallback(
    (selected: ListItem[]) => {
      switch (props.type) {
        case EUploadFromFilesType.chatInput:
          return getNewFilesForChatInput(selected);
        case EUploadFromFilesType.docPage:
          return getNewFilesForDocPage(selected);
        default:
          break;
      }
    },
    [getNewFilesForChatInput, getNewFilesForDocPage, props.type],
  );

  const handleConfirm = useCallback(
    (selected: ListItem[]): void => {
      setShowWorkspaceModal(false);
      const newFiles = getNewFiles(selected);
      onAdd?.(newFiles as T[]);
    },
    [getNewFiles, onAdd],
  );

  return {
    showWorkspaceModal,
    selectedIds,
    handleCancel,
    handleConfirm,
    handleBtnClick,
  };
};

export default useUploadFromFiles;
