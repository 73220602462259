import { Popover } from 'antd';
import classNames from 'classnames';

import { TipsData } from '@/type';
import { useTranslation } from 'react-i18next';

import './index.less';
import CustomIcon from '@/components/CustomIcon';

interface QuestionPopoverProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  items: TipsData[] | null;
  btnText: string;
  btnIcon: string;
  type?: string;
  onChooseQuestion?: (item: TipsData) => void;
}

const QuestionPopover: React.FC<QuestionPopoverProps> = (props) => {
  const { open, onOpenChange, items, btnText, type, onChooseQuestion, btnIcon } = props;
  const { t } = useTranslation();

  const handleChooseQuestion = (item: TipsData) => {
    onChooseQuestion?.(item);
  };

  return (
    <Popover
      open={open}
      placement="top"
      onOpenChange={onOpenChange}
      overlayClassName="question-container"
      title={null}
      content={
        <div className="question-container-content">
          {type === 'presentation' && (
            <div className="question-item header">{t('common.magic')}</div>
          )}
          {items?.map((item, index) => (
            <div
              key={index}
              className="question-item ellipsis"
              onClick={() => handleChooseQuestion(item)}
            >
              {item.title}
            </div>
          ))}
        </div>
      }
      arrow={false}
      getPopupContainer={() =>
        (document.getElementsByClassName('chat-controller')[0] as HTMLElement) ||
        (document.getElementsByClassName('chat-controller2')[0] as HTMLElement)
      }
    >
      <button
        className={classNames({
          active: open,
        })}
      >
        <CustomIcon type={btnIcon} />
        {btnText}
      </button>
    </Popover>
  );
};

export default QuestionPopover;
