/**
 * 文件组件，支持doc、pdf、ppt等格式
 * 支持上传状态的展示
 * 支持上传失败后的重试
 * 支持文件删除
 */
import { useState } from 'react';
import { Progress, Tooltip } from 'antd';

import classNames from 'classnames';
import CustomIcon from '../CustomIcon';
import GoogleIcons from '../CustomIcon/GoogleIcons';

import './index.less';

enum EFileType {
  DOC = 'doc',
  PDF = 'pdf',
  PPT = 'ppt',
}
const lowercaseEFileType = Object.values(EFileType).map((value) => value.toLowerCase());
type FileTypeUnionLowercase = (typeof lowercaseEFileType)[number];

interface FileProps {
  /**文件类型 */
  type: FileTypeUnionLowercase;
  /**文件名 */
  title?: string;
  /**文件补充信息 */
  desc?: string | number;
  /**文件不可用时的提示 */
  disabledTip?: string;
  /**文件错误信息 */
  error?: string;
  /**文件是否能被删除 */
  isCanDelete?: boolean;
  /**是否展示上传进度 */
  showProcess?: boolean;
  /**上传进度 */
  progress?: number;
  onRemove?: () => void;
  onRetry?: () => void;
}

const File: React.FC<FileProps> = (props) => {
  const [_errorOpen, setErrorOpen] = useState(false);

  const {
    type = EFileType.DOC,
    isCanDelete = true,
    showProcess = true,
    title,
    desc,
    error,
    progress,
    disabledTip,
  } = props;

  const handleMouseEnter = () => {
    if (!props?.error) return false;
    setErrorOpen(true);
  };

  const handleMouseLeave = () => {
    if (!props?.error) return false;
    setErrorOpen(false);
  };

  const renderIcon = () => {
    if (type?.includes('google-apps')) {
      return <GoogleIcons type={type?.split('.')?.[2]} />;
    }
    return <CustomIcon className="file-icon" type={`${type}FileBig`} />;
  };

  const renderFile = () => (
    <div className="file-main">
      <div className="file-main-contanier">
        {renderIcon()}
        <div className="file-info">
          <h2 className="ellipsis">{title}</h2>
          <div
            className={classNames({
              desc: true,
              error: error?.length && error.length > 0,
            })}
          >
            <p className="ellipsis">{desc || error}</p>
            {error && (
              <CustomIcon className="redo-icon" type="popai-refresh" onClick={props?.onRetry} />
            )}
          </div>
        </div>
        {showProcess && (
          <Progress
            className="file-progress"
            strokeLinecap="butt"
            size="small"
            strokeColor="#3C38FF"
            trailColor="#E4E6EB"
            showInfo={false}
            percent={progress}
          />
        )}
      </div>
    </div>
  );

  return (
    <div
      className={classNames({
        'file-container': true,
        error: !!error,
        disabled: (disabledTip?.length ?? 0) > 0,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isCanDelete && <CustomIcon className="close-icon" type="close" onClick={props?.onRemove} />}
      {(error || disabledTip) && (
        <Tooltip
          placement="top"
          title={disabledTip || error}
          overlayClassName="sendcontrollerview-file-error-tooltip"
        >
          {renderFile()}
        </Tooltip>
      )}
      {!error && !disabledTip && renderFile()}
    </div>
  );
};

export default File;
