import UserPromptOperatorControl from './Operator/UserPrompt';
import UserPromptSuggestionControl from './Suggestion/UserPrompt';
import { createPortal } from 'react-dom';
import {
  ForwardedRef,
  forwardRef,
  memo,
  startTransition,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { InputContrlProps, InputTypingState } from './types';
import { noop, isFunction } from 'lodash-es';
import { SEARCH_TEMPLATE_KEYWORDS } from '../SendControllerView/constants';
import { useCreationStore, useUserPromptStore, useUserStore } from '@/store';
import { UserPrompt } from '@/type';
import { useDA, useSundry } from '@/hooks';
import { MAX_USER_PROMPT_NAME_COUNT, TIP_TAG } from '@/common/config';
import { useGlobalModal } from '@/layout/BasicLayout';
import { basicChatModelName } from '@/common/model';
import { useTranslation } from 'react-i18next';

function formatHint(str: string) {
  str = str.trim();
  return str.startsWith('/') ? str.substring(1) : str;
}

// function clientSearch(items: UserPrompt[], hint: string): Promise<UserPrompt[]> {
//   return new Promise((resolve) => {
//     const result = items.filter((item) => item.name.includes(hint));
//     resolve(result);
//   });
// }

enum CONTROL_ACTIVE {
  DEFAULT = 0,
  ACTIVE_BY_CLICK = 1,
  ACTIVE_BY_COMMAND = 1,
  ACTIVE_BY_ADD_NEW = 1,
  ACTIVE_BY_HINT_MATCH = 1,
  ACTIVE_BY_INPUT_PASTE = 1,
  DE_ACTIVE_BY_COMMAND = 0,
  DE_ACTIVE_BY_CLICK = 0,
  DE_ACTIVE_BY_ACCEPT_SUGGESTION = 0,
  DE_ACTIVE_BY_CLICK_SUGGESTION_CLOSE = 0,
  DE_ACTIVE_BY_CLICK_OUTSIDE = 0,
  DE_ACTIVE_BY_IMPER_HANDLER = 0,
  DE_ACTIVE_BY_HINT_MATCH = 0,
  DE_ACTIVE_BY_PREVIEW_PPT = 0,
}

enum SUGGESTION_ACTIVE {
  DEFAULT = 0,
  ACTIVE_BY_CLICK_CONTROL = 1,
  ACTIVE_BY_COMMAND = 1,
  ACTIVE_BY_ADD_NEW = 1,
  ACTIVE_BY_HINT_MATCH = 1,
  ACTIVE_BY_INPUT_PASTE = 1,
  DE_ACTIVE_BY_COMMAND = 0,
  DE_ACTIVE_BY_CLICK_CONTROL = 0,
  DE_ACTIVE_BY_ACCEPT_SUGGESTION = 0,
  DE_ACTIVE_BY_CLICK_CLOSE = 0,
  DE_ACTIVE_BY_CLICK_OUTSIDE = 0,
  DE_ACTIVE_BY_HINT_MATCH = 0,
  DE_ACTIVE_BY_IMPER_HANDLER = 0,
  DE_ACTIVE_BY_PREVIEW_PPT = 0,
}

const UserPromptControl = memo(
  forwardRef(
    (
      {
        getSuggestionContainer,
        onSuggestion = noop,
        value,
        renderOperator = true,
        inputTypingState,
        getInputContainer,
        onClick,
        activeOnPasting = false,
        onSuggestionActiveChange,
        displayCount,
        addOnTop,
      }: InputContrlProps & {
        /**
         * text for operator
         */
        text?: string;
        /**
         * element to render the suggestion box
         */
        getSuggestionContainer?: HTMLElement | (() => HTMLElement);
        /**
         * input value
         */
        value: string;
        /**
         * if render tht operator icon
         */
        renderOperator?: boolean;
        /**
         * element to detect if click outside
         */
        getInputContainer?: HTMLElement | (() => HTMLElement);
        /**
         * onClick the operator icon
         */
        onClick?: (event: React.MouseEvent) => void;
        /**
         * if auto show suggestion box after user pasted
         */
        activeOnPasting?: boolean;
        /**
         *
         */
        onSuggestionActiveChange?: (active: boolean) => void;
        /* 显示的 prompt 条数，超出滚动 */
        displayCount?: number;
        /* add按钮渲染位置 */
        addOnTop?: boolean;
      },
      ref: ForwardedRef<{
        deactive: () => void;
      }>,
    ) => {
      const {
        total,
        items,
        search: remoteSearch,
        load,
      } = useUserPromptStore((state) => ({
        items: state.items,
        total: state?.total,
        load: state.load,
        search: state.search,
      }));
      const { userInfo } = useUserStore();
      const hasLogin = userInfo?.isLogin;
      const [controlActive, setControlActive] = useState<CONTROL_ACTIVE>(CONTROL_ACTIVE.DEFAULT);
      const [suggestionActive, setSuggestionActive] = useState<SUGGESTION_ACTIVE>(
        SUGGESTION_ACTIVE.DEFAULT,
      );
      const [displayItems, setDisplayItems] = useState(items);
      const accepted = useRef<string | null>('');
      const { sendDAEvent } = useDA();
      const { updateTip } = useSundry();
      const { checkLoginStatus } = useGlobalModal();
      const { t } = useTranslation();

      const { previewKey, setPreviewKey } = useCreationStore();

      const deactiveOtherSuggestion = useCallback(() => {
        setPreviewKey('');
      }, [setPreviewKey]);

      // 与PPT预览不能同时显示
      useEffect(() => {
        if (previewKey) {
          setSuggestionActive(SUGGESTION_ACTIVE.DE_ACTIVE_BY_PREVIEW_PPT);
          setControlActive(CONTROL_ACTIVE.DE_ACTIVE_BY_PREVIEW_PPT);
        }
      }, [previewKey]);

      const acceptUserPromptSuggestion = useCallback(
        (value: string) => {
          accepted.current = value;
          onSuggestion(value);
          setSuggestionActive(SUGGESTION_ACTIVE.DE_ACTIVE_BY_ACCEPT_SUGGESTION);
          setControlActive(CONTROL_ACTIVE.DE_ACTIVE_BY_ACCEPT_SUGGESTION);
        },
        [onSuggestion],
      );

      // keep accepted until value changed
      useEffect(() => {
        if (accepted.current !== value) {
          accepted.current = null;
        }
      }, [value]);

      const onUserPromptClose = useCallback((): void => {
        setSuggestionActive(SUGGESTION_ACTIVE.DE_ACTIVE_BY_CLICK_CLOSE);
        setControlActive(CONTROL_ACTIVE.DE_ACTIVE_BY_CLICK_SUGGESTION_CLOSE);
      }, []);

      const onControlClick = useCallback(
        (event: React.MouseEvent): void => {
          if (!checkLoginStatus?.({ type: 'user-prompt', model: basicChatModelName })) return;
          updateTip(TIP_TAG.FIRST_SHOW_USER_PROMPT_TIP, 0);
          setControlActive((active) => {
            if (active) {
              setSuggestionActive(SUGGESTION_ACTIVE.DE_ACTIVE_BY_CLICK_CONTROL);
              return CONTROL_ACTIVE.DE_ACTIVE_BY_CLICK;
            } else {
              deactiveOtherSuggestion();
              setSuggestionActive(SUGGESTION_ACTIVE.ACTIVE_BY_CLICK_CONTROL);
              sendDAEvent('ShowMyFrequentPromptList', {
                trigger: 'click_operator',
                button_name: 'ShowPromptList',
              });
              return CONTROL_ACTIVE.ACTIVE_BY_CLICK;
            }
          });
          isFunction(onClick) && onClick(event);
        },
        [checkLoginStatus, deactiveOtherSuggestion, onClick, sendDAEvent, updateTip],
      );

      useImperativeHandle(
        ref,
        () => {
          return {
            deactive() {
              setControlActive(CONTROL_ACTIVE.DE_ACTIVE_BY_IMPER_HANDLER);
              setSuggestionActive(SUGGESTION_ACTIVE.DE_ACTIVE_BY_IMPER_HANDLER);
            },
          };
        },
        [],
      );

      /**
       * auto load after render
       */
      useEffect(() => {
        if (hasLogin) {
          load({ start: 0, limit: 200 });
        }
      }, [hasLogin, load]);

      // update displayItems after load, add or delete
      useEffect(() => {
        setDisplayItems(items);
      }, [items]);

      const isPasting = activeOnPasting && inputTypingState === InputTypingState.PASTING_TEXT;

      useEffect(() => {
        if (isPasting) {
          setSuggestionActive(SUGGESTION_ACTIVE.ACTIVE_BY_INPUT_PASTE);
          setControlActive(CONTROL_ACTIVE.ACTIVE_BY_INPUT_PASTE);
        }
      }, [isPasting]);

      // clear search, reset displayItems to items
      useEffect(() => {
        if (value === '' || value === SEARCH_TEMPLATE_KEYWORDS) {
          setDisplayItems(items);
        }
      }, [items, value]);

      const newItemAdded = useMemo(() => {
        return items.some((item) => item.is_new === true);
      }, [items]);

      // when a prompt is added somewhere
      useEffect(() => {
        if (newItemAdded) {
          setSuggestionActive(SUGGESTION_ACTIVE.ACTIVE_BY_ADD_NEW);
          setControlActive(CONTROL_ACTIVE.ACTIVE_BY_ADD_NEW);
        }
      }, [newItemAdded]);

      // react to SEARCH_TEMPLATE_KEYWORDS
      useEffect(() => {
        if (value.startsWith(SEARCH_TEMPLATE_KEYWORDS)) {
          deactiveOtherSuggestion();
          setControlActive(CONTROL_ACTIVE.ACTIVE_BY_COMMAND);
          setSuggestionActive(SUGGESTION_ACTIVE.ACTIVE_BY_COMMAND);
          sendDAEvent('ShowMyFrequentPromptList', {
            trigger: 'enter_command',
            button_name: 'ShowPromptList',
          });
        }
      }, [deactiveOtherSuggestion, sendDAEvent, value]);

      useEffect(() => {
        if (value === '') {
          setControlActive(CONTROL_ACTIVE.DE_ACTIVE_BY_COMMAND);
          setSuggestionActive(SUGGESTION_ACTIVE.DE_ACTIVE_BY_COMMAND);
        }
      }, [value]);

      // search input value
      const hint = formatHint(value);
      {
        const hintRef = useRef('');
        hintRef.current = hint;
        const lastSearchedHintRef = useRef('');

        const canAutoHiden: boolean =
          !!suggestionActive &&
          !controlActive &&
          !value.startsWith(SEARCH_TEMPLATE_KEYWORDS) &&
          !isPasting &&
          !newItemAdded &&
          lastSearchedHintRef?.current !== hint;

        const afterSearch = useCallback(
          (searchResult: UserPrompt[]) => {
            if (hint === hintRef.current) {
              lastSearchedHintRef.current = hint;
              if (searchResult.length > 0) {
                startTransition(() => {
                  setSuggestionActive(SUGGESTION_ACTIVE.ACTIVE_BY_HINT_MATCH);
                  setControlActive(CONTROL_ACTIVE.ACTIVE_BY_HINT_MATCH);
                  sendDAEvent('ShowMyFrequentPromptList', {
                    trigger: 'match_hint',
                    button_name: 'ShowPromptList',
                  });
                  setDisplayItems(searchResult);
                });
              } else if (canAutoHiden) {
                startTransition(() => {
                  setSuggestionActive(SUGGESTION_ACTIVE.DE_ACTIVE_BY_HINT_MATCH);
                  setControlActive(CONTROL_ACTIVE.DE_ACTIVE_BY_HINT_MATCH);
                });
              }
            } else {
              // ignored after search, searched hint and current hint doesn't match
            }
          },
          [canAutoHiden, hint, sendDAEvent],
        );

        const delayedSearch = useCallback(
          async function delayedSearch(hint: string, delay = 100) {
            await new Promise((res) => setTimeout(res, delay));
            if (!hint || hint !== hintRef.current) {
              // ignored by delay, old hint and new hint doesn't match
              return;
            }

            // clientSearch(items, hint)
            //   .then(afterSearch)
            return remoteSearch({ start: 0, limit: 200, key: hint }).then(afterSearch);
          },
          [afterSearch, remoteSearch],
        );

        useEffect(() => {
          const hasPropmts: boolean = total !== null && total > 0;
          const allowSearch: boolean =
            hasLogin === true &&
            hasPropmts &&
            hint !== '' &&
            hint.length <= MAX_USER_PROMPT_NAME_COUNT &&
            accepted.current !== hint;

          if (allowSearch) {
            delayedSearch(hint);
          }
        }, [delayedSearch, hasLogin, hint, total]);
      }

      // Can we create only one ref which wrapped both of them
      // No, suggestion container will interprete click
      // see suggestion container `interpreteClick`
      const operRef = useRef<HTMLDivElement>(null);
      const sugtRef = useRef<HTMLDivElement>(null);
      {
        const handleClickOutside = useCallback(
          (event: MouseEvent) => {
            const insideOper = operRef.current && operRef.current.contains(event.target as Node);
            const insideSugt = sugtRef.current && sugtRef.current.contains(event.target as Node);
            const inputContainer = isFunction(getInputContainer)
              ? getInputContainer()
              : getInputContainer;
            const insideInputContainer =
              inputContainer && inputContainer.contains(event.target as Node);
            if (!insideOper && !insideSugt && !insideInputContainer && !newItemAdded) {
              startTransition(() => {
                setControlActive(CONTROL_ACTIVE.DE_ACTIVE_BY_CLICK_OUTSIDE);
                setSuggestionActive(SUGGESTION_ACTIVE.DE_ACTIVE_BY_CLICK_OUTSIDE);
              });
            }
          },
          [getInputContainer, newItemAdded],
        );

        useEffect(() => {
          document.addEventListener('click', handleClickOutside);
          return () => {
            document.removeEventListener('click', handleClickOutside);
          };
        }, [handleClickOutside]);
      }

      useEffect(() => {
        isFunction(onSuggestionActiveChange) && onSuggestionActiveChange(suggestionActive === 1);
      }, [onSuggestionActiveChange, suggestionActive]);

      const suggestionElement = (
        <UserPromptSuggestionControl
          hint={hint}
          onSuggestion={acceptUserPromptSuggestion}
          onClose={onUserPromptClose}
          active={!!suggestionActive}
          items={displayItems}
          key="user-prompt-suggestion-control"
          ref={sugtRef}
          displayCount={displayCount}
          addOnTop={addOnTop}
        />
      );

      return (
        <>
          {renderOperator && (
            <div ref={operRef}>
              <UserPromptOperatorControl
                toolTip={t('components.userPrompt.myPrompt')}
                active={!!controlActive}
                onClick={onControlClick}
                key="user-prompt-oper-control"
              />
            </div>
          )}
          {suggestionActive
            ? getSuggestionContainer
              ? createPortal(
                  suggestionElement,
                  isFunction(getSuggestionContainer)
                    ? getSuggestionContainer()
                    : getSuggestionContainer,
                )
              : suggestionElement
            : null}
        </>
      );
    },
  ),
);

export default UserPromptControl;
