import { Modal, Button, Checkbox, Radio } from 'antd';
import Icon from '@/components/Icon';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import styles from './index.module.less';
import { memo, useCallback, useRef, useState } from 'react';
import { ListItem, ScreenTypeEnum } from '@/pages/WorkSpace/typing/index';
import { getWorkspaceFilelist } from '@/services/workspace';
import FileIcon from '../FileIcon';
import AutoLoadMore from '@/components/AutoLoadMore';
import { FileType, getFileType } from '@/common/helpers/fileHelper';
import { isEqual, noop } from 'lodash-es';
import { useDA } from '@/hooks';
import { Channel } from '@/type';
import { getChatType } from '@/common/helpers/chatHelper';
import type { ResponseType } from '@/type';
import noData from '@/assets/workspace/list-nodata.svg';

const PAGE_SIZE = 24;

enum ModalCloseState {
  cancel,
  confirm,
}

const WorkspaceModal = memo(
  ({
    onCancel,
    onConfirm,
    selected: defaultSelected,
    multiSelection = true,
    channel,
  }: {
    onCancel: () => void;
    onConfirm: (selected: ListItem[]) => void;
    selected?: string[];
    /**
     * 多选还是单选
     */
    multiSelection: boolean;
    channel?: Channel;
  }) => {
    const { sendDAEvent } = useDA();
    const { t } = useTranslation();
    const modalContainerRef = useRef<HTMLDivElement>(null);
    const pageRef = useRef(0);
    const closeStateRef = useRef<ModalCloseState>(ModalCloseState.cancel);
    const [files, setFiles] = useState<ListItem[] | undefined>(undefined);
    // 只记录本次弹窗的选择
    const [selected, setSelected] = useState<string[]>([]);
    const [open, setOpen] = useState(true);

    const stopPropagation: React.EventHandler<React.MouseEvent> = useCallback((e) => {
      e.stopPropagation();
      e.preventDefault();
    }, []);

    const getModalContainer = useCallback(() => {
      return modalContainerRef.current as HTMLDivElement;
    }, []);

    const loadFiles = useCallback(async () => {
      try {
        const res = await getWorkspaceFilelist<ResponseType<ListItem[]>>({
          page: pageRef.current,
          pagesize: PAGE_SIZE,
          generate_type: ScreenTypeEnum.Uploaded,
          minutesOffset: new Date().getTimezoneOffset(),
          types: [FileType.doc, FileType.pdf].join(','),
        });
        pageRef.current++;
        const newItems = res?.data || [];
        setFiles((oldItems) => {
          return [...(oldItems ?? []), ...newItems];
        });
        return newItems.length === PAGE_SIZE;
      } catch (e) {
        onCancel();
        return true;
      }
    }, [onCancel]);

    const handleItemClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        const currentKey = (e.currentTarget as HTMLElement).dataset['key'];
        if (currentKey) {
          setSelected((selected) => {
            if (multiSelection) {
              if (selected.includes(currentKey)) {
                sendDAEvent('UploadFiles_AddFromWorkspace_CheckBox_Click', {
                  channelid: channel?.channelId,
                  chattype: channel ? getChatType(channel) : '',
                  file_doc_id: currentKey,
                  is_on: false,
                });
                return selected.filter((key) => key !== currentKey);
              } else {
                sendDAEvent('UploadFiles_AddFromWorkspace_CheckBox_Click', {
                  channelid: channel?.channelId,
                  chattype: channel ? getChatType(channel) : '',
                  file_doc_id: currentKey,
                  is_on: true,
                });
                return [...selected, currentKey];
              }
            } else {
              sendDAEvent('UploadFiles_AddFromWorkspace_CheckBox_Click', {
                channelid: channel?.channelId,
                chattype: channel ? getChatType(channel) : '',
                file_doc_id: currentKey,
                is_on: true,
              });
              return [currentKey];
            }
          });
        }
      },
      [multiSelection, sendDAEvent, channel],
    );

    const handleConfirm = useCallback((): void => {
      closeStateRef.current = ModalCloseState.confirm;
      setOpen(false);
    }, []);

    const handleCancel = useCallback(
      (button_type: string): void => {
        closeStateRef.current = ModalCloseState.cancel;
        setOpen(false);
        sendDAEvent('UploadFiles_AddFromWorkspace', {
          channelid: channel?.channelId || '',
          chattype: (channel?.channelId && getChatType(channel)) || '',
          button_type,
        });
      },
      [channel, sendDAEvent],
    );

    // delay onCancal and onConfirm in open change for animation
    const handleOpenChange = useCallback(
      (open: boolean): void => {
        if (!open) {
          if (closeStateRef.current === ModalCloseState.cancel) {
            onCancel();
          } else {
            const selectedFiles = files?.filter((file) => selected.includes(file.uniqKey));
            sendDAEvent('UploadFiles_AddFromWorkspace', {
              channelid: channel?.channelId || '',
              chattype: (channel?.channelId && getChatType(channel)) || '',
              button_type: 'confirm',
              selected_files: selectedFiles?.map((item) => item.uniqKey).join(','),
            });
            selectedFiles && onConfirm(selectedFiles);
          }
        }
      },
      [onCancel, files, sendDAEvent, channel, onConfirm, selected],
    );

    const SelctionInput = multiSelection ? Checkbox : Radio;

    return (
      <div onClick={stopPropagation} ref={modalContainerRef}>
        <Modal
          getContainer={getModalContainer}
          className={styles.dialog}
          destroyOnClose={false}
          centered
          afterOpenChange={handleOpenChange}
          width={720}
          open={open}
          mask={true}
          maskClosable={false}
          footer={[
            <div className={styles.buttons} key="form-footer">
              <Button onClick={() => handleCancel('cancel')} key="cancel">
                {t('common.cancel')}
              </Button>
              <Button
                disabled={selected.length === 0}
                type="primary"
                onClick={handleConfirm}
                key="submit"
              >
                {t('common.confirm')}
              </Button>
            </div>,
          ]}
          closeIcon={<Icon name="popai-no" onClick={() => handleCancel('close')} />}
          title={t('components.upload.addFromFilesModalTitle')}
        >
          <AutoLoadMore
            load={loadFiles}
            className={cs(
              (files?.length ?? 0) === 0 ? styles['inner-ct--loading'] : styles['inner-ct'],
            )}
          >
            {files && files.length === 0 && (
              <div className={styles['inner-ct--empty']}>
                <img src={noData} />
                <p>{t('common.recentsEmpty')}</p>
              </div>
            )}
            {files &&
              files.length > 0 &&
              files.map((item) => {
                const defaultChecked = defaultSelected?.includes(item.uniqKey);
                return (
                  <div
                    className={defaultChecked ? styles['item--disabled'] : styles.item}
                    key={item.uniqKey}
                    data-key={item.uniqKey}
                    onClick={defaultChecked ? noop : handleItemClick}
                  >
                    <SelctionInput
                      checked={defaultChecked || selected.includes(item.uniqKey)}
                      disabled={defaultChecked}
                    />
                    <FileIcon
                      size="medium"
                      className={styles['fileicon']}
                      type={getFileType(item.fileType || item.rawFileTypeEnum)}
                    ></FileIcon>
                    <div className={styles.filename}>{item.fileName}</div>
                  </div>
                );
              })}
          </AutoLoadMore>
        </Modal>
      </div>
    );
  },
  (preProps, nextProps) => {
    // use deep equal, for selected prop, we only care the ids
    return isEqual(preProps, nextProps);
  },
);

export default WorkspaceModal;
