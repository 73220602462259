import { useCallback, useRef, useState } from 'react';
import { InputControlState, InputTypingState } from '../types';
import { useInputMessageStore } from '@/store';
import { useDA } from '@/hooks';

/**
 * 追踪input输入框的状态
 */
function useInputTracker(initValue: string) {
  const { preparePropertyFor } = useDA();
  const [typingState, setTypingState] = useState<InputTypingState>(InputTypingState.TYPING);
  const [activeControl, switchControl] = useState<InputControlState>(InputControlState.NONE);
  const {
    pasted,
    setPasted: setPasted,
    setValue,
    markSent,
  } = useInputMessageStore((state) => ({
    pasted: state.currentMessage.pasted,
    setPasted: state.setPasted,
    setValue: state.setValue,
    markSent: state.markSent,
  }));

  const pastePending = useRef(false);

  const valueRef = useRef<string>(initValue);

  const trackInput = useCallback(
    (value: string) => {
      valueRef.current = value;
      setValue(value);
      if (value === '') {
        setPasted(false);
        preparePropertyFor('Chat_SendMessage', 'message_type', 'user_prompt');
      }

      if (pastePending.current) {
        pastePending.current = false;
      } else {
        setTypingState(InputTypingState.TYPING);
      }
    },
    [preparePropertyFor, setPasted, setValue],
  );

  const trackPaste = useCallback(
    (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
      const items = event?.clipboardData?.items || [];
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.includes('text')) {
          setTypingState(InputTypingState.PASTING_TEXT);
          setPasted(true);
          pastePending.current = true;
          break;
        }
      }
    },
    [setPasted],
  );

  const trackSend = useCallback(() => {
    markSent();
  }, [markSent]);

  return {
    /**
     * state indicate user is typing or pasting
     */
    typingState,
    /**
     * 输入框是否粘贴过
     *
     * 一旦粘贴过，除非全部清空或者主动trackPasted(false), 或者发送，否则将一直保持true
     */
    pasted,
    /**
     * 更新输入框的粘贴状态
     *
     * 一般不需要主动调用，本追踪器会自动更新
     */
    setPasted,
    /**
     * state indicate which control is active
     * for future
     */
    activeControl,
    /**
     * switch active control to xxx
     * for future
     */
    switchControl,
    /**
     * on input change callback, used to detect typingState
     */
    trackInput,
    /**
     * on input paste callback, used to detect typingState
     */
    trackPaste,
    /**
     * call this function after message sent, will update the input message store
     */
    trackSend,
  };
}

export default useInputTracker;
