import { Suspense } from 'react';

interface LazySuspenseProps {
  children: JSX.Element;
}

const LazySuspense: React.FC<LazySuspenseProps> = (props) => {
  const { children } = props;

  return (
    <Suspense>
      {children}
    </Suspense>
  );
}

function withSuspense<T extends JSX.IntrinsicAttributes>(WrappedComponent: React.FC<T>) {
  const LazySuspenseWrapper: React.FC<T> = (props) => (
    <LazySuspense>
      <WrappedComponent {...props} />
    </LazySuspense>
  );

  return LazySuspenseWrapper;
}

export default withSuspense;
