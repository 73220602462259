import classNames from 'classnames';
import { STORE_KEY } from '@/common/config';
import ToastContent from '@/components/ToastContent';
import { useGlobalModal } from '@/layout/BasicLayout';
import {
  useUserStore,
  useEnhanceUploadStore,
  // ENHANCE_UPLOAD_STATUS,
  useSendUploadStore,
} from '@/store';
import { App, Upload } from 'antd';
import { useDA, useGASendEvent } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { shouldUserUseEnhance } from '@/common/gray';
import { DOC_FILE_MIME_WHITE_LIST, PPT_FILE_MIME_WHITE_LIST } from '@/common/config';

import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import './index.less';

export interface UploadEnhanceRef {
  uploadMethod?: (params: CustomUploadParams) => Promise<any>;
}

export interface FinishUploadParams {
  imageUrls?: string[];
  model?: string;
}

interface UploadEnhanceProps {
  className?: string;
  children?: React.ReactNode;
  onStartUpload?: () => void;
  onClick?: () => void;
  onFinishUpload?: (params?: FinishUploadParams) => void;
  isQuickAccess?: boolean;
}

interface CustomUploadParams {
  file: UploadFile;
  url?: string;
}

// 允许上传文件类型
const FILE_MIME_WHITE_LIST = [...PPT_FILE_MIME_WHITE_LIST, ...DOC_FILE_MIME_WHITE_LIST];

const UploadToEnhance: React.FC<UploadEnhanceProps> = (props) => {
  const { className = '', children, onStartUpload, onFinishUpload, isQuickAccess } = props;

  const { message } = App.useApp();
  const { t } = useTranslation();
  const { sendEvent } = useGASendEvent();
  const { sendDAEvent, preparePropertyFor } = useDA();
  const { userInfo } = useUserStore();
  const { isLogin = false, isPro } = userInfo;
  const isEnhanceBtnShow = shouldUserUseEnhance(userInfo);
  const { checkLoginStatus } = useGlobalModal();
  const { upload, parsingFile, fileType } = useEnhanceUploadStore();
  const { onReset } = useSendUploadStore();

  const openFileDialogOnClick = !!isLogin;

  /**
   * 未登录和未付费拦截
   */
  const handleFakeClick = () => {
    sendEvent('ClickCardEnhanceUpload');
    sendDAEvent('UploadFiles_ButtonClick', { channelid: '', chattype: '' });
    preparePropertyFor(
      'RegistLoginModalExposure',
      'forward_source',
      isQuickAccess ? 'ppt_block_enhance_click' : 'input_enhance_click',
    );
    if (!isLogin || !isPro) {
      handleLogin();
    }
    // else if (!isPro ) {
    //   validatePay()
    // }
  };

  /**
   * 未登录账号登录拦截
   */
  const handleLogin = () => {
    checkLoginStatus?.({ type: '', model: 'fake' });
  };

  /**
   * 验证待上传文件类型是否合法
   * @param type 文件类型
   * @returns
   */
  const validateFileType = (type: string) => {
    return FILE_MIME_WHITE_LIST?.includes(type);
  };

  /**
   * 上传逻辑
   */
  const uploadMethod = async ({ file }: CustomUploadParams) => {
    onStartUpload?.();
    const startUploadTime = Date.now();
    let parseStartTime = 0;
    try {
      const md5 = await upload(file);
      const uploadTime = Date.now() - startUploadTime;
      // 上传成功埋点
      sendEvent('CompleteEnhanceDocument', {
        status: 'success',
        fileName: file.name,
        fileType,
        uploadTime,
      });
      if (md5) {
        parseStartTime = Date.now();
        await parsingFile(md5);
        const parseTime = Date.now() - parseStartTime;
        // 解析成功埋点
        sendEvent('CompleteParseEnhanceDocument', {
          parseStatus: 'success',
          fileName: file.name,
          fileType,
          parseTime,
        });
      }
      sendDAEvent('UploadDocumentResult', {
        is_success: true,
        file_type: fileType,
        filename: file.name || file.fileName,
        md5,
        duration: uploadTime / 1000,
        failurereason: '',
      });
    } catch (error: any) {
      if (error.message == 'upload failed') {
        const uploadTime = Date.now() - startUploadTime;
        // 上传失败埋点
        sendEvent('CompleteEnhanceDocument', {
          status: 'failed',
          fileName: file.name,
          fileType,
          uploadTime,
        });
        sendDAEvent('UploadDocumentResult', {
          is_success: false,
          file_type: fileType,
          filename: file.name || file.fileName,
          md5: '',
          duration: uploadTime / 1000,
          failurereason: error.message,
        });
      }
      if (error.message == 'parse failed') {
        // 解析失败
        const parseTime = Date.now() - parseStartTime;
        sendEvent('CompleteParseEnhanceDocument', {
          parseStatus: 'failed',
          fileName: file?.name,
          fileType,
          parseTime,
        });
      }
      onFinishUpload?.();
    }
  };

  const uploadProps: UploadProps = {
    name: 'file',
    accept: '.ppt,.pptx,.pdf,.doc,.docx',
    multiple: false,
    headers: {
      Authorization: localStorage.getItem(STORE_KEY.Authorization) as string,
    },
    beforeUpload: (file: RcFile) => {
      sendEvent('SelectEnhanceDocument');
      const isValidateFile = validateFileType(file?.type ?? '');
      if (!isValidateFile) {
        message.open({
          content: (
            <ToastContent icon="error" content={t('components.EnhanceUpload.uploadLimit')} />
          ),
        });
      }
      // 限制文件大小
      const isFileSizeValid = file.size / 1024 / 1024 <= 20;
      if (!isFileSizeValid) {
        message.open({
          content: (
            <ToastContent icon="error" content={t('components.EnhanceUpload.toolTipTittle')} />
          ),
        });
      }
      return (isValidateFile && isFileSizeValid) || Upload.LIST_IGNORE;
    },
    showUploadList: false,
    openFileDialogOnClick,
    customRequest: async (option: any) => {
      await uploadMethod({ file: option.file });
    },
  };

  const onUploadClick = () => {
    sendEvent('ClickCardEnhanceUpload');
    sendDAEvent('UploadFiles_ButtonClick', { channelid: '', chattype: '' });
    onStartUpload?.();
    // 清空one chat输入框中上传的文件及其状态
    onReset();
  };

  if (!isEnhanceBtnShow) return null;
  return (
    <>
      {!isLogin && (
        <div className={className} onClick={handleFakeClick}>
          {children}
        </div>
      )}
      {isLogin && (
        <Upload
          {...uploadProps}
          className={classNames({
            disabled: !openFileDialogOnClick,
            [className]: !!className,
          })}
        >
          <div style={{ display: 'flex' }} onClick={onUploadClick}>
            {children}
          </div>
        </Upload>
      )}
    </>
  );
};

export default UploadToEnhance;
