import classNames from 'classnames';

interface InputSizeTipProps {
  maximum?: boolean;
  inputExceeded?: boolean;
  value?: string;
  maxCount?: number;
}
const InputSizeTip: React.FC<InputSizeTipProps> = (props) => {
  const { maximum = false, inputExceeded = false, value = '', maxCount } = props;
  if (maximum) {
    return (
      <div
        className={classNames({
          'size-tip': true,
          'size-tip-error': inputExceeded,
        })}
      >
        <span>{value?.length}</span>
        <span>/{maxCount}</span>
      </div>
    );
  }
  return null;
};

export default InputSizeTip;
