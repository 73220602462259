import { useTranslation } from 'react-i18next';
import styles from './banner.module.less';
import { HTMLAttributes } from 'react';
import cs from 'classnames';

function SavePromptBanner({ className }: HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation();
  return (
    <div className={cs(styles.banner_save, className)}>
      <span>{t('components.userPrompt.saveBannerText')}</span>
    </div>
  );
}

export default SavePromptBanner;
