import CustomIcon from '@/components/CustomIcon';
import UserBadge from '@/layout/BasicLayout/components/UserBadge';
import { useUserStore } from '@/store';
import { Button } from 'antd';
import classNames from 'classnames';

interface SendButtonProps {
  isShowUnlimitedIcon?: boolean;
  canSend?: boolean;
  sendLoading?: boolean;
  onSend: () => void;
}

const SendButton: React.FC<SendButtonProps> = (props) => {
  const { userInfo } = useUserStore();

  const { isShowUnlimitedIcon, canSend, sendLoading, onSend } = props;

  return (
    <Button
      disabled={!canSend}
      className={classNames({
        'operator-send': true,
        disabled: !canSend,
      })}
      loading={sendLoading}
      onClick={onSend}
    >
      <CustomIcon type={sendLoading ? '' : 'sendChat'} />
      {userInfo?.isUnlimited && isShowUnlimitedIcon && (
        <UserBadge className="unlimited-icon" renderType="featureHint" />
      )}
    </Button>
  );
};
export default SendButton;
