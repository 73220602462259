import CreatePresentationButton from '@/components/CreatePresentationButton';
import CustomIcon from '@/components/CustomIcon';
import { useTranslation } from 'react-i18next';

interface ProcessOperatorsProps {
  stopShow?: boolean;
  onStopGenerate?: () => void;
  createPPTShow?: boolean;
  pdfMsgId?: string;
}

const ProcessOperators: React.FC<ProcessOperatorsProps> = (props) => {
  const { stopShow = false, createPPTShow = false, pdfMsgId = '' } = props;

  const { t } = useTranslation();

  return (
    <div className="prompt-generators">
      {stopShow && (
        <button onClick={props?.onStopGenerate}>
          <CustomIcon className="stop-icon" type="stop" />
          <span>{t('common.stopGenerating')}</span>
        </button>
      )}
      {createPPTShow && <CreatePresentationButton pdfMsgId={pdfMsgId} />}
    </div>
  );
};

export default ProcessOperators;
