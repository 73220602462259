import CustomIcon from '@/components/CustomIcon';
import { ListItem } from '../../types';
import { debounce } from 'lodash-es';
import './index.less';

interface Props {
  item: ListItem;
  onClick: (data: ListItem) => void;
}

/** card单项卡片 */
const Card = (props: Props) => {
  const { item, onClick } = props;
  const { title, description } = item;

  const clickHandler = debounce(async () => {
    onClick && onClick(item);
  }, 300);

  return (
    <div className="card_new" onClick={clickHandler}>
      <CustomIcon type={item.icon} className="card_new__icon" />
      <div className="card_new__title">
        <div className="card_new__title-text">{title}</div>
        <div className="card_new__desc" title={description}>
          {description}
        </div>
      </div>
      <div className="isRtlIcon_language_ar">
        <CustomIcon type="popai-right" className="card_new__arrow" />
      </div>
    </div>
  );
};

export default Card;
