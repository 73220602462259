import React, { useCallback } from 'react';
import cs from 'classnames';
import CustomIcon from '@/components/CustomIcon';

import styles from './view.module.less';
import { Tooltip } from 'antd';

interface IInputControlViewProps {
  onClick?: (e: React.MouseEvent) => void;

  className?: string;
  /**
   * will apply `type` to CustomIcon component
   */
  iconType: string;
  /**
   * tooltip text to display when mouse hove
   */
  tooltip?: string;
  /**
   * tell if icon is in active state, default false
   */
  active?: boolean;
  /**
   * disabled icon can't click, default false
   */
  disabled?: boolean;
}

const InputControlView = React.memo(
  ({
    onClick,
    className,
    iconType,
    tooltip,
    active = false,
    disabled = false,
    text,
  }: IInputControlViewProps & { text?: string }) => {
    const handleClick = useCallback(
      (e: React.MouseEvent) => {
        if (!disabled && typeof onClick === 'function') {
          onClick(e);
        }
      },
      [disabled, onClick],
    );

    return text ? (
      <Tooltip title={tooltip} placement="top">
        <div className={styles.ct} onClick={handleClick}>
          <CustomIcon
            className={cs('operator', className, {
              active,
              disabled,
            })}
            type={iconType}
          />
          {text ? <span className={styles.text}>{text}</span> : null}
        </div>
      </Tooltip>
    ) : (
      <CustomIcon
        className={cs('operator', className, {
          active,
          disabled,
        })}
        tooltip={tooltip}
        onClick={handleClick}
        type={iconType}
      />
    );
  },
);

export default InputControlView;
